import { Alert, ErrorBoundary, LoadingPlaceholder, Text } from '@grafana/ui';
import { Suspense, useDeferredValue } from 'react';

import { K8sSummaryCardGrid } from './K8sSummaryCardGrid';

interface K8sSummaryContainerType {
  cluster: string;
  namespace: string;
  image: string;
  tankaTeam: string;
  githubTeam: string;
  outOfSlo: string;
}

export const K8sSummaryContainer = ({
  cluster,
  namespace,
  image,
  tankaTeam,
  githubTeam,
  outOfSlo,
}: K8sSummaryContainerType) => {
  const deferredCluster = useDeferredValue(cluster);
  const deferredNamespace = useDeferredValue(namespace);
  const deferredImage = useDeferredValue(image);
  const deferredTankaTeam = useDeferredValue(tankaTeam);
  const deferredGithubTeam = useDeferredValue(githubTeam);
  const deferredOutOfSlo = useDeferredValue(outOfSlo);

  return (
    <ErrorBoundary>
      {({ error }) => {
        if (error) {
          return (
            <Alert
              title="Error: failed to load versions"
              onRemove={() => window.location.reload()}
              buttonContent="Reload"
            >
              {error.message}
            </Alert>
          );
        }
        return deferredNamespace.length ? (
          <Suspense fallback={<LoadingPlaceholder text="Loading..." />}>
            <K8sSummaryCardGrid
              cluster={deferredCluster}
              namespace={deferredNamespace}
              image={deferredImage}
              tankaTeam={deferredTankaTeam}
              githubTeam={deferredGithubTeam}
              outOfSlo={deferredOutOfSlo}
            />
          </Suspense>
        ) : (
          <Text color="secondary" italic>
            Select a namespace to see images
          </Text>
        );
      }}
    </ErrorBoundary>
  );
};
