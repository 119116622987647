import { useSuspenseQuery } from '@apollo/client';
import { Cve, GetCveReportQuery } from '__generated__/graphql';

import { GET_CVE_REPORT } from './CveReportQueries';
import { CveSummaryTable } from './CveSummaryTable';

interface CveSummaryTableFetcherCveProps {
  cve: string;
}

export const CveSummaryTableFetcherCve = ({ cve }: CveSummaryTableFetcherCveProps) => {
  const { data } = useSuspenseQuery<GetCveReportQuery>(GET_CVE_REPORT, {
    variables: {
      filters: {
        cve,
      },
      first: 1,
    },
  });

  const cveData = data.cves.response[0];

  return <CveSummaryTable cve={cveData as Cve} />;
};
