import { CustomCellRendererProps, Icon, Stack, Tooltip, useTheme2 } from '@grafana/ui';
import { Link } from 'react-router-dom';
import { SOURCES_ROUTE } from 'shared/constants/routes/appRoutes';

const ActionsCell = (props: CustomCellRendererProps, sourceID: string, versionID: string) => {
  const theme = useTheme2();
  const issueID = props.value;

  return (
    <Stack justifyContent="space-evenly">
      <Link to={`${SOURCES_ROUTE}/${sourceID}/version/${versionID}/issue/${issueID}`}>
        <Tooltip content="Full Details">
          <Icon name="book" size="lg" color={theme.visualization.getColorByName('blue')} />
        </Tooltip>
      </Link>
    </Stack>
  );
};

export { ActionsCell };
