import { TypedDocumentNode, gql } from '@apollo/client';
import {
  ExceptionDetailsUpdateCveExceptionMutation,
  ExceptionDetailsUpdateCveExceptionMutationVariables,
} from '__generated__/graphql';

export const EXCEPTION_DETAILS_UPDATE_CVE_EXCEPTION: TypedDocumentNode<
  ExceptionDetailsUpdateCveExceptionMutation,
  ExceptionDetailsUpdateCveExceptionMutationVariables
> = gql`
  mutation exceptionDetailsUpdateCveException($input: UpdateCveExceptionInput!) {
    updateCveException(input: $input) {
      id
    }
  }
`;
