import {
  EmbeddedScene,
  SceneAppPage,
  SceneControlsSpacer,
  SceneFlexItem,
  SceneFlexLayout,
  SceneReactObject,
  SceneVariableSet,
  VariableValueSelectors,
} from '@grafana/scenes';
import { VariableHide } from '@grafana/schema';
import { Icon } from '@grafana/ui';
import { VersionExceptionAlert } from 'components/CveExceptions';
import { FederalBanner } from 'components/FederalBanner';
import { K8sNoSloVersionBanner } from 'components/K8sNoSloBanner';
import { StaleSloBanner } from 'components/StaleSloBanner';
import { RadioToggle } from 'controls/RadioToggle';
import { TextInputVariable } from 'controls/TextInputVariable';
import {
  ISSUE_DETAILS_ROUTE_PATH,
  SOURCES_ROUTE,
  VERSION_VULNERABILITIES_ROUTE_PATH,
} from 'shared/constants/routes/appRoutes';

import { getIssueCveDrilldown } from './getIssueCveDrilldown';
import { getCveSplitLayout, getPackageSplitLayout } from './layouts';
import { getCveTotalsOutOfSloStat, getCveTotalsStat } from './panels';
import { getCvesWithSloQueryRunner } from './queryRunners/getCvesWithSloQueryRunner';

interface GetVulnerabilitiesTabType {
  apiUrl: string;
  sourceID: string;
  versionID: string;
}

export const getVulnerabilitiesTab = ({ apiUrl, sourceID, versionID }: GetVulnerabilitiesTabType) => {
  const cveVariable = new TextInputVariable({
    name: 'cve',
    prefix: <Icon name="search" />,
    placeholder: 'Search CVEs',
    width: 40,
    hide: VariableHide.hideLabel,
  });

  const packageVariable = new TextInputVariable({
    name: 'pkg',
    prefix: <Icon name="search" />,
    placeholder: 'Search Packages',
    width: 40,
    hide: VariableHide.hideLabel,
  });

  const cveSplitLayout = getCveSplitLayout(apiUrl, sourceID, versionID);
  const packageSplitLayout = getPackageSplitLayout(apiUrl, sourceID, versionID);

  const layout = new SceneFlexLayout({
    children: [
      new SceneFlexItem({
        width: '100%',
        minHeight: '500px',
        key: 'splitScene',
        body: cveSplitLayout,
      }),
    ],
  });

  const viewToggle = new RadioToggle({
    options: [
      { value: 'cve', label: 'By CVE' },
      { value: 'package', label: 'By Package' },
    ],
    value: 'cve',
    onChange: (value) => {
      if (value === 'cve') {
        layout.setState({ children: [new SceneFlexItem({ body: cveSplitLayout })] });
      } else {
        layout.setState({ children: [new SceneFlexItem({ body: packageSplitLayout })] });
      }
    },
  });

  return new SceneAppPage({
    title: 'Vulnerabilities',
    titleIcon: 'bug',
    key: 'vulnerabilitiesTab',
    url: `${SOURCES_ROUTE}/${sourceID}/version/${versionID}/vulnerabilities`,
    routePath: VERSION_VULNERABILITIES_ROUTE_PATH,
    getScene: () => {
      return new EmbeddedScene({
        key: 'vulnerabilities',
        $variables: new SceneVariableSet({
          variables: [cveVariable, packageVariable],
        }),
        body: new SceneFlexLayout({
          direction: 'column',
          children: [
            new SceneReactObject({
              component: () => FederalBanner({ sourceID }),
            }),
            new SceneReactObject({
              component: () => StaleSloBanner({ versionID }),
            }),
            new SceneReactObject({
              component: () => K8sNoSloVersionBanner({ versionID }),
            }),
            new SceneReactObject({
              component: () => VersionExceptionAlert({ versionID, sourceID }),
            }),
            new SceneFlexLayout({
              direction: 'row',
              width: '100%',
              height: '110px',
              $data: getCvesWithSloQueryRunner(apiUrl, versionID),
              children: [
                new SceneFlexItem({
                  width: '50%',
                  body: getCveTotalsStat(),
                }),
                new SceneFlexItem({
                  width: '50%',
                  body: getCveTotalsOutOfSloStat(),
                }),
              ],
            }),
            layout,
          ],
        }),
        controls: [new VariableValueSelectors({}), new SceneControlsSpacer(), viewToggle],
      });
    },
    drilldowns: [
      {
        routePath: ISSUE_DETAILS_ROUTE_PATH,
        getPage(routeMatch, parent) {
          const sourceID = routeMatch.params.sourceID;
          const versionID = routeMatch.params.versionID;
          const issueID = routeMatch.params.issueID;

          return getIssueCveDrilldown(parent, sourceID, versionID, issueID);
        },
      },
    ],
  });
};
