import { TypedDocumentNode, gql } from '@apollo/client';
import { GetCveExceptionCommentsQuery, GetCveExceptionCommentsQueryVariables } from '__generated__/graphql';

export const GET_CVE_EXCEPTION_COMMENTS: TypedDocumentNode<
  GetCveExceptionCommentsQuery,
  GetCveExceptionCommentsQueryVariables
> = gql`
  query getCveExceptionComments($id: ID!) {
    cveException(id: $id) {
      id
      comments {
        id
        comment
        userId
        userName
        date
        gravatarUrl
      }
    }
  }
`;
