import { ApolloProvider } from '@apollo/client';
import { AppRootProps } from '@grafana/data';
import { apolloClient } from 'apollo/apolloClient';
import { PluginPropsContext } from 'shared/pluginUtils';

import { App } from './App';

export const AppProvider = (props: AppRootProps) => {
  return (
    <PluginPropsContext.Provider value={props}>
      <ApolloProvider client={apolloClient}>
        <App />
      </ApolloProvider>
    </PluginPropsContext.Provider>
  );
};
