import { useQuery } from '@apollo/client';
import { Button, Stack } from '@grafana/ui';
import { GetExceptionHeaderQuery } from '__generated__/graphql';
import { GET_EXCEPTION_HEADER } from 'components/CveExceptions/ExceptionDetailsHeader/ExceptionDetailsHeaderQueries';
import { useState } from 'react';

import { ModifyExceptionControlsModal } from './ModifyExceptionControlsModal';

export enum ExceptionUpdate {
  RENEW,
  ACTIVATE,
  DEACTIVATE,
  DELETE,
}

interface ModifyExceptionControlsProps {
  exceptionID: string;
}

export const ModifyExceptionControls = ({ exceptionID }: ModifyExceptionControlsProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [updateType, setUpdateType] = useState<ExceptionUpdate>(ExceptionUpdate.RENEW);

  const { loading, error, data, refetch } = useQuery<GetExceptionHeaderQuery>(GET_EXCEPTION_HEADER, {
    variables: { id: exceptionID },
  });

  const cveException = data?.cveException;

  const onSuccess = () => {
    setIsModalOpen(false);
    refetch();
  };

  const onClick = (updateType: ExceptionUpdate) => {
    setUpdateType(updateType);
    setIsModalOpen(true);
  };

  const isExpired = new Date(cveException?.expiresOn) < new Date();
  const isActive = cveException?.isActive;

  // Can activate if exception is not expired but is inactive
  const canActivate = !isExpired && !isActive;
  // Can deactivate if exception is active
  const canDeactivate = !isExpired && isActive;

  return (
    <Stack alignItems="center">
      <Button variant="success" size="sm" disabled={loading || !!error} onClick={() => onClick(ExceptionUpdate.RENEW)}>
        Renew Exception
      </Button>
      {canActivate && (
        <Button
          variant="success"
          size="sm"
          disabled={loading || !!error}
          onClick={() => onClick(ExceptionUpdate.ACTIVATE)}
        >
          Activate Exception
        </Button>
      )}
      {canDeactivate && (
        <Button
          variant="primary"
          size="sm"
          disabled={loading || !!error}
          onClick={() => onClick(ExceptionUpdate.DEACTIVATE)}
        >
          Deactivate Exception
        </Button>
      )}
      {/* Hiding delete button as we may not want to delete exceptions for audit purposes */}
      {/* <Button
        variant="destructive"
        size="sm"
        disabled={loading || !!error}
        onClick={() => onClick(ExceptionUpdate.DELETE)}
      >
        Delete Exception
      </Button> */}
      <ModifyExceptionControlsModal
        isOpen={isModalOpen}
        exceptionID={cveException?.id!}
        onDismiss={() => setIsModalOpen(false)}
        onSuccess={onSuccess}
        updateType={updateType}
      />
    </Stack>
  );
};
