import { useQuery } from '@apollo/client';
import { Stack, Text } from '@grafana/ui';
import { GetExceptionHeaderQuery } from '__generated__/graphql';

import { GET_EXCEPTION_HEADER } from './ExceptionDetailsHeaderQueries';

interface ExceptionDetailsHeaderType {
  exceptionID: string;
}

export const ExceptionDetailsHeader = ({ exceptionID }: ExceptionDetailsHeaderType) => {
  const { data, loading, error } = useQuery<GetExceptionHeaderQuery>(GET_EXCEPTION_HEADER, {
    variables: {
      id: exceptionID,
    },
  });

  if (loading || error) {
    return null;
  }

  const { cve } = data!.cveException;

  return (
    <Stack direction="column" gap={1}>
      <Text variant="h1">⚠️ Exception: {cve.cve}</Text>
      <Text element="h4" color="secondary">
        {cve.title}
      </Text>
    </Stack>
  );
};
