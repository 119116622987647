import { css } from '@emotion/css';
import { GrafanaTheme2, renderMarkdown } from '@grafana/data';
import { Box, LinkButton, Text, useStyles2 } from '@grafana/ui';
import { Cve } from '__generated__/graphql';
import { useMemo } from 'react';

interface CveDescriptionType {
  cve: Cve;
}

export const CveDescription = ({ cve }: CveDescriptionType) => {
  const styles = useStyles2(getStyles);

  const isMarkdownHeading = useMemo(() => cve.description.trim().startsWith('#'), [cve.description]);
  const renderedMarkdown = useMemo(() => renderMarkdown(cve.description), [cve.description]);

  return (
    <Box backgroundColor="canvas" borderColor="strong" borderStyle="solid" marginBottom={2} padding={1.5}>
      <div className={styles.container}>
        {!isMarkdownHeading && (
          <Box paddingBottom={1}>
            <Text element="h2">Overview</Text>
          </Box>
        )}
        {cve.description.trim() && (
          <span
            className="markdown-html"
            // nosemgrep: typescript.react.security.audit.react-dangerouslysetinnerhtml.react-dangerouslysetinnerhtml
            dangerouslySetInnerHTML={{ __html: renderedMarkdown }}
          />
        )}
        <Box display="flex" justifyContent="right" marginTop={2}>
          <LinkButton
            href={cve.url}
            target="_blank"
            rel="noreferrer"
            variant="primary"
            icon="external-link-alt"
            size="sm"
          >
            {cve.url && cve.url.includes('github.com') ? 'View on GitHub' : 'More Details'}
          </LinkButton>
          {cve.cve && !cve.cve.includes('GHSA') && (
            <Box marginLeft={2}>
              <LinkButton
                href={`https://cve.mitre.org/cgi-bin/cvename.cgi?name=${cve.cve}`}
                target="_blank"
                rel="noreferrer"
                variant="primary"
                icon="external-link-alt"
                size="sm"
              >
                View on Mitre
              </LinkButton>
            </Box>
          )}
        </Box>
      </div>
    </Box>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  container: css({
    maxHeight: '540px',
    overflowY: 'auto',
    paddingRight: theme.spacing(2),
  }),
});
