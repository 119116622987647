import {
  EmbeddedScene,
  SceneAppPage,
  SceneAppPageLike,
  SceneFlexItem,
  SceneFlexLayout,
  SceneReactObject,
} from '@grafana/scenes';
import { IssueHeader } from 'components/IssueHeader';
import { IssueReportContainer } from 'components/IssueReport';
import { K8sNoSloIssueBanner } from 'components/K8sNoSloBanner';
import { IssueOwnershipControls } from 'components/Ownership';
import { ISSUE_DETAILS_ROUTE_PATH, SOURCES_ROUTE } from 'shared/constants/routes/appRoutes';

export const getIssueCveDrilldown = (parent: SceneAppPageLike, sourceID: string, versionID: string, issueID: string) =>
  new SceneAppPage({
    title: 'Issue Report',
    renderTitle: () => IssueHeader({ issueID }),
    key: 'issueReportDrilldown',
    getParentPage: () => parent,
    url: `${SOURCES_ROUTE}/${sourceID}/version/${versionID}/issue/${issueID}`,
    controls: [
      new SceneReactObject({
        component: () => <IssueOwnershipControls issueId={issueID} />,
      }),
    ],
    routePath: ISSUE_DETAILS_ROUTE_PATH,
    getScene: () => {
      return new EmbeddedScene({
        key: 'issueReport',
        body: new SceneFlexLayout({
          direction: 'column',
          maxWidth: '100%',
          children: [
            new SceneReactObject({
              component: () => K8sNoSloIssueBanner({ versionID }),
            }),
            new SceneFlexItem({
              body: new SceneReactObject({
                component: () => IssueReportContainer({ issueID }),
              }),
            }),
          ],
        }),
      });
    },
  });
