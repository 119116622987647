import { EmbeddedScene, SceneAppPage, SceneFlexLayout, SceneReactObject } from '@grafana/scenes';
import { AppMenu } from 'components/AppMenu';
import { MENU_ROUTE, MENU_ROUTE_PATH } from 'shared/constants/routes/appRoutes';

export const getMenuPage = () => {
  return new SceneAppPage({
    title: 'Vulnerability Observability',
    subTitle: 'Manage and explore your vulnerability posture using Grafana',
    key: 'menuPage',
    url: MENU_ROUTE,
    routePath: MENU_ROUTE_PATH,
    getScene: () => {
      return new EmbeddedScene({
        key: 'menu',
        body: new SceneFlexLayout({
          direction: 'column',
          width: '100%',
          children: [
            new SceneFlexLayout({
              width: '100%',
              height: 'max-content',
              children: [
                new SceneReactObject({
                  component: AppMenu,
                }),
              ],
            }),
          ],
        }),
      });
    },
  });
};
