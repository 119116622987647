import { css, cx } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { useTheme2, Tooltip, PopoverContent, Icon } from '@grafana/ui';
import { constructGitHubUrl } from 'components/utils';
import { PropsWithChildren } from 'react';

import { User } from './UsersIndicator';

export interface UserIconProps {
  /** An object that contains the user's details and 'lastActiveAt' status */
  user: User;
  /** Custom tooltip for user icon */
  tooltipContent?: PopoverContent;
}

export const UserIcon = ({ user, children, tooltipContent }: PropsWithChildren<UserIconProps>) => {
  const theme = useTheme2();
  const styles = getStyles(theme);
  const content = (
    <a
      href={constructGitHubUrl(user.githubName, !user.isTeam)}
      type={'button'}
      className={cx(styles.container)}
      aria-label={`${user.githubName} icon`}
      target="_blank"
      rel="noreferrer"
    >
      {children ? (
        <div className={cx(styles.content, styles.textContent)}>{children}</div>
      ) : user.avatarUrl ? (
        <img className={styles.content} src={user.avatarUrl} alt={`${user.githubName} avatar`} />
      ) : (
        <div className={cx(styles.content, styles.textContent, styles.userIcon)}>{<Icon name="user" size="lg" />}</div>
      )}
    </a>
  );

  if (tooltipContent) {
    return (
      <Tooltip content={tooltipContent} placement="auto-end" interactive>
        {content}
      </Tooltip>
    );
  } else {
    return content;
  }
};

const getIconBorder = (color: string): string => {
  return `0 0 0 1px ${color}`;
};

export const getStyles = (theme: GrafanaTheme2) => ({
  container: css({
    padding: 0,
    width: '30px',
    height: '30px',
    background: 'none',
    border: 'none',
    borderRadius: theme.shape.radius.circle,
    '& > *': {
      borderRadius: theme.shape.radius.circle,
    },
  }),
  content: css({
    lineHeight: '24px',
    maxWidth: '100%',
    border: `3px ${theme.colors.background.primary} solid`,
    boxShadow: getIconBorder(theme.colors.border.medium),
    backgroundClip: 'padding-box',
    '&:hover': {
      boxShadow: getIconBorder(theme.colors.border.strong),
    },
  }),
  textContent: css({
    background: theme.colors.background.primary,
    padding: 0,
    color: theme.colors.text.secondary,
    textAlign: 'center',
    fontSize: theme.typography.bodySmall.fontSize,
    '&:focus': {
      boxShadow: getIconBorder(theme.colors.border.medium),
    },
  }),
  pointer: css({
    cursor: 'pointer',
  }),
  userIcon: css({
    color: theme.colors.secondary.main,
  }),
});
