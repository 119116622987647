import { useMutation } from '@apollo/client';
import { css } from '@emotion/css';
import { AppEvents, GrafanaTheme2 } from '@grafana/data';
import { config, getAppEvents } from '@grafana/runtime';
import { useStyles2, Field, ReactMonacoEditor, Stack, Button } from '@grafana/ui';
import { CreateCveExceptionCommentMutation } from '__generated__/graphql';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import { CREATE_CVE_EXCEPTION_COMMENT } from './ExceptionCommentsMutations';
import { GET_CVE_EXCEPTION_COMMENTS } from './ExceptionCommentsQueries';

interface FormType {
  comment: string;
}

interface AddExceptionCommentFormProps {
  exceptionID: string;
}

export const AddExceptionCommentForm = ({ exceptionID }: AddExceptionCommentFormProps) => {
  const styles = useStyles2(getStyles);

  const [createCveExceptionComment] = useMutation<CreateCveExceptionCommentMutation>(CREATE_CVE_EXCEPTION_COMMENT, {
    refetchQueries: [GET_CVE_EXCEPTION_COMMENTS],
    ignoreResults: true,
    onError: () => {
      getAppEvents().publish({
        type: AppEvents.alertError.name,
        payload: [`Error: failed to create comment`],
      });
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<FormType>({
    defaultValues: {
      comment: '',
    },
  });

  const onSubmit: SubmitHandler<FormType> = ({ comment }) => {
    const user = config.bootData.user;
    const payload = {
      variables: {
        input: {
          exceptionId: exceptionID,
          comment,
          userId: user.id,
          userName: user.name,
          gravatarUrl: user.gravatarUrl,
        },
      },
    };

    createCveExceptionComment(payload);
    reset();
  };

  const user = config.bootData.user;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <div className={styles.absContainer}>
        <Field>
          <Controller
            name="comment"
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
              <ReactMonacoEditor
                {...field}
                language="markdown"
                height="120px"
                className={styles.editor}
                options={{
                  overviewRulerLanes: 0,
                  wordWrap: 'on',
                  renderLineHighlight: 'none',
                  folding: false,
                  lineNumbers: 'off',
                  lineDecorationsWidth: 8,
                  fontSize: 14,
                  minimap: {
                    enabled: false,
                  },
                  scrollbar: {
                    vertical: 'hidden',
                  },
                }}
              />
            )}
          />
        </Field>
        <Stack justifyContent="space-between" alignItems="center">
          <em>Working as: {user.name || 'anonymous'}</em>
          <Button type="submit" size="sm" disabled={!user.isSignedIn || !isValid}>
            Add Comment
          </Button>
        </Stack>
      </div>
    </form>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  form: css({
    minHeight: '164px',
  }),
  absContainer: css({
    width: '100%',
    position: 'absolute',
  }),
  editor: css({
    background: theme.colors.background.canvas,
    border: `1px solid ${theme.colors.border.weak}`,
    padding: `${theme.spacing(1)} ${theme.spacing(0)}`,
  }),
});
