import { useQuery } from '@apollo/client';
import { Alert } from '@grafana/ui';
import { GetFromK8sScanOnlyQuery } from '__generated__/graphql';

import { GET_FROM_K8S_SCAN_ONLY } from './K8sNoSloBannerQueries';

interface K8sNoSloIssueBannerType {
  versionID: string;
}

export const K8sNoSloIssueBanner = ({ versionID }: K8sNoSloIssueBannerType) => {
  const { data, loading, error } = useQuery<GetFromK8sScanOnlyQuery>(GET_FROM_K8S_SCAN_ONLY, {
    variables: {
      id: versionID,
    },
  });

  if (loading || error) {
    return null;
  }

  const fromK8sOnly = data?.version.fromK8sScan && !data?.version.fromConfigScan;

  const k8sClusters = data?.version.k8sNamespaces.map((k8sNamespace) => k8sNamespace.cluster.name);
  const hasProdCluster = k8sClusters?.some((cluster) => cluster.includes('prod'));

  return fromK8sOnly && !hasProdCluster ? (
    <Alert title="This issue doesn't have an SLO!" severity="info">
      SLOs are reserved for issues from versions that are detected as part of a configured scan or from a production K8s
      cluster.
      {!hasProdCluster && ` This issue's version was only detected in a non-production K8s cluster.`}
    </Alert>
  ) : null;
};
