import { useLazyQuery } from '@apollo/client';
import { AppEvents, SelectableValue } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Select, Field } from '@grafana/ui';
import { GetCveListQuery } from '__generated__/graphql';
import { capitalizeFirstLetter, debounce } from 'components/utils';
import { useCallback, useEffect, useState } from 'react';
import { Control, Controller, FormState, Path } from 'react-hook-form';

import type { ExceptionFormType } from '../CreateException';
import { GET_CVE_LIST } from '../CreateExceptionQueries';

interface CveFieldType<T extends ExceptionFormType> {
  control: Control<T>;
  formState: FormState<T>;
  required?: boolean;
}

export const CveField = <T extends ExceptionFormType>({ control, formState, required }: CveFieldType<T>) => {
  const [options, setOptions] = useState<SelectableValue[]>([]);

  const [fetchCves, { loading: loadingCveList }] = useLazyQuery<GetCveListQuery>(GET_CVE_LIST, {
    onCompleted(data) {
      setOptions(
        data.cves.response.map((cve) => ({
          label: cve.cve,
          value: cve.id,
          description: capitalizeFirstLetter(cve.title),
        }))
      );
    },
    onError: () => {
      getAppEvents().publish({
        type: AppEvents.alertError.name,
        payload: [`Error: failed to fetch CVEs`],
      });
    },
  });

  const fetchDefaultCves = useCallback(() => {
    fetchCves({ variables: { first: 25 } });
  }, [fetchCves]);

  useEffect(() => {
    fetchDefaultCves();
  }, [fetchDefaultCves]);

  const debouncedFetch = debounce((fetchFn: (query: string) => void, query: string) => {
    fetchFn(query);
  }, 250);

  const debouncedFetchCves = useCallback(
    (query: string) => {
      if (query.length > 2) {
        debouncedFetch(() => fetchCves({ variables: { filters: { cve: query }, first: 25 } }), query);
      }
    },
    [fetchCves, debouncedFetch]
  );

  return (
    <Field label={`CVE${required ? '*' : ''}`}>
      <Controller
        name={'cve' as Path<T>}
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <Select
            {...field}
            allowCustomValue={false}
            placeholder="Start typing to search..."
            options={options}
            isLoading={loadingCveList}
            width={45}
            invalid={!!formState.errors.cve}
            onInputChange={debouncedFetchCves}
            onBlur={fetchDefaultCves}
          />
        )}
      />
    </Field>
  );
};
