import {
  EmbeddedScene,
  SceneAppPage,
  SceneFlexLayout,
  SceneFlexItem,
  VariableValueSelectors,
  SceneTimePicker,
  SceneTimeRange,
  SceneVariableSet,
  QueryVariable,
  SceneControlsSpacer,
} from '@grafana/scenes';
import { SwitchVariable } from 'controls/SwitchVariable';
import { SourceOverviewTable } from 'customScenes/SourceOverviewTable';
import { createInfinityVariable, getInfinityDatasource } from 'datasources/infinity';
import { OVERVIEW_ROUTE, OVERVIEW_ROUTE_PATH } from 'shared/constants/routes/appRoutes';

import {
  getCveTotalsStat,
  getMeanTimeToResolveTimeseries,
  getVersionSloBarGauge,
  getCvesPatchedTimeseries,
  getSecretTotalsStat,
} from './overview/panels';
import {
  getSloTotalsQueryRunner,
  getTotalCveCountsQueryRunner,
  getTotalSecretsCountsQueryRunner,
  getWeeklyCvesPatchedQueryRunner,
  getWeeklyTimeToResolutionQueryRunner,
} from './overview/queryRunners';

interface GetOverviewTabType {
  apiUrl: string;
}

export const getOverviewTab = ({ apiUrl }: GetOverviewTabType) => {
  const groupVariable = new QueryVariable({
    name: 'group',
    label: 'Group',
    datasource: getInfinityDatasource(),
    placeholder: 'Select Group',
    includeAll: true,
    defaultToAll: true,
    allValue: '0',
    value: '0',
    query: createInfinityVariable({
      refId: 'variable-group',
      apiUrl,
      queryBody: `
        query getGroups {
          groups {
            __value: id
            __text: name
          }
        }
      `,
      overrides: {
        base: {
          root_selector: 'data.groups',
        },
      },
    }),
  });

  const latestOnlyVariable = new SwitchVariable({
    name: 'latestOnly',
    label: 'Latest Versions Only',
    description: 'Only include data from the latest version of each source',
  });

  const onlyIssuesWithSloVariable = new SwitchVariable({
    name: 'onlyIssuesWithSlo',
    label: 'Hide CVEs Without SLOs',
    description: 'Hides CVEs that are missing a CVSS score and/or an available fix',
  });

  const excludeK8sSources = new SwitchVariable({
    name: 'excludeK8sSources',
    label: 'Exclude K8s Sources',
    description: 'Hide sources that are only scanned as part of a K8s cluster scan',
    value: '1',
  });

  const outOfSlo = new SwitchVariable({
    name: 'outOfSlo',
    label: 'Out of SLO',
    description: 'Only show CVEs that are out of SLO',
  });

  return new SceneAppPage({
    title: 'Overview',
    titleIcon: 'legend-show',
    key: 'overviewTab',
    url: OVERVIEW_ROUTE,
    routePath: OVERVIEW_ROUTE_PATH,
    getScene: () => {
      return new EmbeddedScene({
        key: 'overviewScene',
        $timeRange: new SceneTimeRange({
          from: 'now-90d',
          to: 'now',
        }),
        $variables: new SceneVariableSet({
          variables: [groupVariable, latestOnlyVariable, onlyIssuesWithSloVariable, excludeK8sSources, outOfSlo],
        }),
        body: new SceneFlexLayout({
          width: '100%',
          direction: 'column',
          children: [
            new SceneFlexLayout({
              width: '100%',
              height: '130px',
              children: [
                new SceneFlexItem({
                  $data: getTotalCveCountsQueryRunner(apiUrl),
                  body: getCveTotalsStat(),
                }),
                new SceneFlexItem({
                  $data: getSloTotalsQueryRunner(apiUrl),
                  body: getVersionSloBarGauge(),
                }),
                new SceneFlexItem({
                  width: '15%',
                  $data: getTotalSecretsCountsQueryRunner(apiUrl),
                  body: getSecretTotalsStat(),
                }),
              ],
            }),
            new SceneFlexLayout({
              width: '100%',
              height: '300px',
              children: [
                new SceneFlexItem({
                  $data: getWeeklyTimeToResolutionQueryRunner(apiUrl),
                  body: getMeanTimeToResolveTimeseries(),
                }),
                new SceneFlexItem({
                  $data: getWeeklyCvesPatchedQueryRunner(apiUrl),
                  body: getCvesPatchedTimeseries(),
                }),
              ],
            }),
            new SceneFlexItem({
              body: new SourceOverviewTable({
                group: '${group}',
                latestOnly: '${latestOnly}',
                onlyIssuesWithSlo: '${onlyIssuesWithSlo}',
                excludeK8sSources: '${excludeK8sSources}',
                outOfSlo: '${outOfSlo}',
              }),
            }),
          ],
        }),
        controls: [new VariableValueSelectors({}), new SceneControlsSpacer(), new SceneTimePicker({})],
      });
    },
  });
};
