import { useQuery } from '@apollo/client';
import { Alert } from '@grafana/ui';
import { GetVersionExceptionAlertQuery } from '__generated__/graphql';
import { useNavigate } from 'react-router-dom';
import { CVE_EXCEPTIONS_ROUTE } from 'shared/constants/routes/appRoutes';

import { GET_VERSION_EXCEPTION_ALERT } from './VersionExceptionAlertQueries';

interface VersionExceptionAlertProps {
  versionID: string;
  sourceID: string;
}

export const VersionExceptionAlert = ({ versionID, sourceID }: VersionExceptionAlertProps) => {
  const navigate = useNavigate();
  const { data, loading, error } = useQuery<GetVersionExceptionAlertQuery>(GET_VERSION_EXCEPTION_ALERT, {
    variables: {
      filters: {
        versionId: versionID,
        isActive: true,
      },
    },
  });

  if (loading || error) {
    return null;
  }

  if (!data?.cveExceptions.metadata.totalCount) {
    return null;
  }

  return (
    <Alert
      title="This Version is Affected by a CVE Exception"
      severity="warning"
      onRemove={() => navigate(`${CVE_EXCEPTIONS_ROUTE}?var-source=${sourceID}&var-version=${versionID}`)}
      buttonContent="See Details"
    >
      A CVE exception has been created that may impact this version. Review the exception details to understand the risk
      and required actions.
    </Alert>
  );
};
