import { useSuspenseQuery } from '@apollo/client';
import { css, cx } from '@emotion/css';
import { Stack, Text, useStyles2, useTheme2, Icon } from '@grafana/ui';
import { GetSourceSummariesWithSloQuery, SourceWithSlo } from '__generated__/graphql';
import { Direction, useSorting } from 'hooks/useSorting/useSorting';
import { memo, useMemo } from 'react';

import { BaseTable } from '../base';

import { GET_SOURCES_SUMMARIES_WITH_SLO } from './SourceOverviewTableQueries';
import { SourceOverviewTableRow } from './SourceOverviewTableRow';

interface SourceOverviewTableType {
  group: string;
  latestOnly: string;
  onlyIssuesWithSlo: string;
  excludeK8sSources: string;
  outOfSlo: string;
}

const SourceOverviewTable = memo(
  ({ group, latestOnly, onlyIssuesWithSlo, excludeK8sSources, outOfSlo }: SourceOverviewTableType) => {
    const styles = useStyles2(getStyles);
    const theme = useTheme2();

    const { data } = useSuspenseQuery<GetSourceSummariesWithSloQuery>(GET_SOURCES_SUMMARIES_WITH_SLO, {
      variables: {
        filters: {
          groupId: group,
          latestOnly,
          onlyIssuesWithSlo,
          fromConfigScanOnly: excludeK8sSources,
          outOfSlo,
        },
      },
      fetchPolicy: 'cache-and-network',
    });

    const sourceSummaries = useMemo(() => data?.summary?.sourcesWithSlo || [], [data]);

    const flattenedSummaries = sourceSummaries.map((source) => ({
      ...source,
      critical: source.totalCveCounts.critical,
      high: source.totalCveCounts.high,
      medium: source.totalCveCounts.medium,
      low: source.totalCveCounts.low,
    }));

    const { sortedData, handleSort, sortConfig } = useSorting(flattenedSummaries, 'lowestSloRemaining', Direction.Asc);

    const renderSortIcon = (key: keyof SourceWithSlo['totalCveCounts'] | 'lowestSloRemaining' | 'totalSecrets') => {
      if (sortConfig.key !== key) {
        return null;
      }
      const isLowestSlo = key === 'lowestSloRemaining';
      const isAscending = sortConfig.direction === Direction.Asc;
      const showArrowUp = isLowestSlo ? !isAscending : isAscending;
      return showArrowUp ? <Icon name="arrow-up" /> : <Icon name="arrow-down" />;
    };

    return (
      <Stack direction="column">
        {sortedData.length ? (
          <>
            <BaseTable theme={theme}>
              <thead>
                <tr>
                  <th className={styles.center}>Type</th>
                  <th>Name</th>
                  <th>Groups</th>
                  <th className={styles.sortable} onClick={() => handleSort('totalSecrets')}>
                    Exposed Secrets {renderSortIcon('totalSecrets')}
                  </th>
                  <th className={styles.sortable} onClick={() => handleSort('critical')}>
                    Critical {renderSortIcon('critical')}
                  </th>
                  <th className={styles.sortable} onClick={() => handleSort('high')}>
                    High {renderSortIcon('high')}
                  </th>
                  <th className={styles.sortable} onClick={() => handleSort('medium')}>
                    Medium {renderSortIcon('medium')}
                  </th>
                  <th className={styles.sortable} onClick={() => handleSort('low')}>
                    Low {renderSortIcon('low')}
                  </th>
                  <th
                    className={cx(styles.sortable, styles.sloComplianceColumn)}
                    onClick={() => handleSort('lowestSloRemaining')}
                  >
                    SLO Compliance {renderSortIcon('lowestSloRemaining')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedData.map((sourceSummary, index) => (
                  <SourceOverviewTableRow
                    sourceSummary={sourceSummary as unknown as SourceWithSlo}
                    key={`${sourceSummary.source.id}-${index}`}
                  />
                ))}
              </tbody>
            </BaseTable>
          </>
        ) : (
          <Text italic color="secondary">
            No Sources
          </Text>
        )}
      </Stack>
    );
  }
);

SourceOverviewTable.displayName = 'SourceOverviewTable';

export { SourceOverviewTable };

const getStyles = () => ({
  center: css({
    textAlign: 'center',
  }),
  sortable: css({
    textAlign: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  }),
  sloComplianceColumn: css({
    minWidth: '164px',
  }),
});
