import { getAppEvents } from '@grafana/runtime';
import {
  EmbeddedScene,
  QueryVariable,
  SceneAppPage,
  SceneFlexItem,
  SceneFlexLayout,
  SceneVariableSet,
  VariableValueSelectors,
} from '@grafana/scenes';
import { VariableHide, VariableSort } from '@grafana/schema';
import { Icon } from '@grafana/ui';
import { ShowBasedOnConditionBehavior } from 'behaviors/ShowBasedOnConditionBehavior';
import { SwitchVariable } from 'controls/SwitchVariable';
import { TextInputVariable } from 'controls/TextInputVariable';
import { K8s } from 'customScenes/K8s';
import { createInfinityVariable, getInfinityDatasource } from 'datasources/infinity';
import { K8S_SCANNING_ROUTE, K8S_VERSION_DETAILS_ROUTE_PATH } from 'shared/constants/routes/appRoutes';
import { K8sCardNamespaceClickedEvent } from 'shared/events';

import { getCveTotalsStat } from './panels';
import { getClusterCveCountsQueryRunner, getNamespaceCveCountsQueryRunner } from './queryRunners';

interface GetVersionsTabType {
  apiUrl: string;
  clusterID: string;
}

export const getVersionsTab = ({ apiUrl, clusterID }: GetVersionsTabType) => {
  const namespaceVariable = new QueryVariable({
    key: 'namespace',
    name: 'namespace',
    label: 'Namespace',
    datasource: getInfinityDatasource(),
    sort: VariableSort.alphabeticalCaseInsensitiveAsc,
    includeAll: true,
    defaultToAll: true,
    allValue: '0',
    value: '$__all',
    query: createInfinityVariable({
      refId: 'variable-namespace',
      apiUrl,
      queryBody: `
        query getNamespaces($id: ID!) {
          k8sCluster(id: $id) {
            namespaces {
              __value: id
              __text: name
            }
          }
        }`,
      variables: {
        id: clusterID,
      },
      overrides: {
        base: {
          root_selector: 'data.k8sCluster.namespaces',
        },
      },
    }),
  });

  const imageVariable = new TextInputVariable({
    name: 'image',
    prefix: <Icon name="gf-layout-simple" />,
    placeholder: 'Search Images',
    width: 40,
    hide: VariableHide.hideLabel,
  });

  const tankaTeamVariable = new QueryVariable({
    name: 'tankaTeam',
    label: 'Tanka Team',
    datasource: getInfinityDatasource(),
    placeholder: 'Select Tanka Team',
    includeAll: true,
    defaultToAll: true,
    allValue: 'ALL',
    value: '$__all',
    query: createInfinityVariable({
      refId: 'variable-tanka-team',
      apiUrl,
      queryBody: `
        query getTeams {
          teams {
            __value: tankaTeam
          }
        }
      `,
      overrides: {
        base: {
          root_selector: 'data.teams',
        },
      },
    }),
  });

  const githubTeamVariable = new QueryVariable({
    name: 'githubTeam',
    label: 'GitHub Team',
    datasource: getInfinityDatasource(),
    placeholder: 'Select GitHub Team',
    includeAll: true,
    defaultToAll: true,
    allValue: 'ALL',
    value: '$__all',
    query: createInfinityVariable({
      refId: 'variable-github-team',
      apiUrl,
      queryBody: `
        query getTeams {
          teams {
            __value: githubTeam
          }
        }
      `,
      overrides: {
        base: {
          root_selector: 'data.teams',
        },
      },
    }),
  });

  const outOfSloVariable = new SwitchVariable({
    name: 'outOfSlo',
    label: 'Out of SLO',
    description: 'Only show CVEs that are out of SLO. We only track CVEs for production clusters.',
  });

  getAppEvents().subscribe(K8sCardNamespaceClickedEvent, ({ payload }) => {
    namespaceVariable.changeValueTo(payload.id, payload.name);
  });

  return new SceneAppPage({
    title: 'Versions',
    key: 'k8sVersions',
    url: `${K8S_SCANNING_ROUTE}/${clusterID}/versions`,
    routePath: K8S_VERSION_DETAILS_ROUTE_PATH,
    getScene: () => {
      return new EmbeddedScene({
        key: 'k8sVersionTab',
        $variables: new SceneVariableSet({
          variables: [imageVariable, namespaceVariable, tankaTeamVariable, githubTeamVariable, outOfSloVariable],
        }),
        body: new SceneFlexLayout({
          direction: 'column',
          children: [
            new SceneFlexLayout({
              direction: 'row',
              width: '100%',
              height: '110px',
              children: [
                new SceneFlexItem({
                  $data: getClusterCveCountsQueryRunner(apiUrl, clusterID),
                  body: getCveTotalsStat({ title: 'Cluster CVE Totals' }),
                }),
                new SceneFlexItem({
                  $data: getNamespaceCveCountsQueryRunner(apiUrl),
                  body: getCveTotalsStat({ title: 'Namespace CVE Totals' }),
                  $behaviors: [
                    new ShowBasedOnConditionBehavior({
                      references: ['namespace'],
                      condition: (namespace: QueryVariable) => namespace.state.value !== '$__all',
                    }),
                  ],
                }),
              ],
            }),
            new K8s({
              cluster: clusterID,
              namespace: '${namespace}',
              image: '${image}',
              tankaTeam: '${tankaTeam}',
              githubTeam: '${githubTeam}',
              outOfSlo: '${outOfSlo}',
            }),
          ],
        }),
        controls: [new VariableValueSelectors({})],
      });
    },
  });
};
