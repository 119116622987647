import { Stack, Tooltip } from '@grafana/ui';

interface StatusProps {
  daysUntilExpiration: number;
  isActive: boolean;
}

export const Status = ({ daysUntilExpiration, isActive }: StatusProps) => {
  let statusText = 'Inactive';
  let statusEmoji = '❌';

  if (isActive) {
    if (daysUntilExpiration > 30) {
      statusText = 'Active';
      statusEmoji = '✅';
    } else if (daysUntilExpiration > 0) {
      statusText = 'Expiring Soon';
      statusEmoji = '⚠️';
    }
  }

  return (
    <Tooltip content={statusText}>
      <Stack justifyContent="center">{statusEmoji}</Stack>
    </Tooltip>
  );
};
