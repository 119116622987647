import { config, usePluginInteractionReporter } from '@grafana/runtime';
import { SceneApp } from '@grafana/scenes';
import { Alert, Box, ErrorBoundaryAlert } from '@grafana/ui';
import { useEffect, useMemo, useState } from 'react';
import { APP_VISIT_EVENT } from 'shared/constants/trackingEvents';
import { useApiUrl } from 'shared/pluginUtils';

import { INFINITY_DATASOURCE_NAME, getInfinityDatasource } from '../datasources/infinity';
import { PROMETHEUS_DATASOURCE_NAME, getPrometheusDatasource } from '../datasources/prometheus';
import { useRole } from '../hooks';
import {
  getBugBountyPage,
  getCvesPage,
  getK8sScanningPage,
  getSettingsPage,
  getMenuPage,
  getProjectsPage,
} from '../pages';

const getSceneApp = ({ apiUrl }: { apiUrl: string }) =>
  new SceneApp({
    name: 'security-vulnerability-observability-app',
    pages: [
      getProjectsPage({ apiUrl }),
      getK8sScanningPage({ apiUrl }),
      getCvesPage({ apiUrl }),
      getBugBountyPage({ apiUrl }),
      getSettingsPage({ apiUrl }),
      getMenuPage(),
    ],
  });

export const App = () => {
  // Prefetch role to cache the result
  useRole();

  const apiUrl = useApiUrl();
  const report = usePluginInteractionReporter();

  const [datasourceErrors, setDatasourceErrors] = useState<string[]>([]);

  useEffect(() => {
    const errors: string[] = [];
    if (!getInfinityDatasource()) {
      errors.push(`Missing ${INFINITY_DATASOURCE_NAME} datasource!`);
    }
    if (!getPrometheusDatasource()) {
      errors.push(`Missing ${PROMETHEUS_DATASOURCE_NAME} datasource!`);
    }
    setDatasourceErrors(errors);
  }, []);

  useEffect(() => {
    report(APP_VISIT_EVENT, { id: config.bootData.user.id });
  }, [report]);

  const scene = useMemo(() => getSceneApp({ apiUrl }), [apiUrl]);

  if (datasourceErrors.length > 0) {
    return (
      <Box margin={2}>
        {datasourceErrors.map((error, index) => (
          <Alert key={index} title={error} onRemove={() => window.location.reload()} buttonContent="Reload">
            Vuln O11y depends on the missing datasource. Please configure it and reload the page.
          </Alert>
        ))}
      </Box>
    );
  }

  return (
    <ErrorBoundaryAlert>
      <scene.Component model={scene} />
    </ErrorBoundaryAlert>
  );
};
