import { useSuspenseQuery } from '@apollo/client';
import { Alert, ErrorBoundary } from '@grafana/ui';
import { GetSourceOwnersQuery } from '__generated__/graphql';

import { OwnerBox } from './OwnerBox';
import { GET_SOURCE_OWNERS } from './OwnershipControlQueries';
import { ResourceType } from './UsersIndicator';

interface OwnershipHeaderType {
  sourceId: string;
}

const Controls = ({ sourceId }: OwnershipHeaderType) => {
  const {
    data: { source },
  } = useSuspenseQuery<GetSourceOwnersQuery>(GET_SOURCE_OWNERS, {
    variables: {
      id: sourceId,
    },
  });
  return <OwnerBox owners={source.owners} type={ResourceType.SOURCE} />;
};

export const SourceOwnershipControls = ({ sourceId }: OwnershipHeaderType) => (
  <ErrorBoundary>
    {({ error }) => {
      if (error) {
        return (
          <Alert title="Error: failed to load owners" onRemove={() => window.location.reload()} buttonContent="Reload">
            {error.message}
          </Alert>
        );
      }
      return <Controls sourceId={sourceId} />;
    }}
  </ErrorBoundary>
);
