import { Alert, ErrorBoundary, LoadingBar } from '@grafana/ui';
import { Suspense, useDeferredValue } from 'react';

import { SourceOverviewTable } from './SourceOverviewTable';

interface SourceOverviewTableContainerType {
  group: string;
  latestOnly: string;
  onlyIssuesWithSlo: string;
  excludeK8sSources: string;
  outOfSlo: string;
}

export const SourceOverviewTableContainer = ({
  group,
  latestOnly,
  onlyIssuesWithSlo,
  excludeK8sSources,
  outOfSlo,
}: SourceOverviewTableContainerType) => {
  const deferredGroup = useDeferredValue(group);
  const deferredLatestOnly = useDeferredValue(latestOnly);
  const deferredOnlyIssuesWithSlo = useDeferredValue(onlyIssuesWithSlo);
  const deferredExcludeK8sSources = useDeferredValue(excludeK8sSources);
  const deferredOutOfSlo = useDeferredValue(outOfSlo);

  return (
    <ErrorBoundary>
      {({ error }) => {
        if (error) {
          return (
            <Alert
              title="Error: failed to load sources"
              onRemove={() => window.location.reload()}
              buttonContent="Reload"
            >
              {error.message}
            </Alert>
          );
        }
        return (
          <Suspense fallback={<LoadingBar width={1000} />}>
            <SourceOverviewTable
              group={deferredGroup}
              latestOnly={deferredLatestOnly}
              onlyIssuesWithSlo={deferredOnlyIssuesWithSlo}
              excludeK8sSources={deferredExcludeK8sSources}
              outOfSlo={deferredOutOfSlo}
            />
          </Suspense>
        );
      }}
    </ErrorBoundary>
  );
};
