import { SceneAppPage, SceneAppPageLike, SceneReactObject } from '@grafana/scenes';
import { SourceHeader, SourceHeaderControls } from 'components/SourceHeader';
import { SOURCE_DETAILS_ROUTE_PATH, SOURCES_ROUTE } from 'shared/constants/routes/appRoutes';

import { getCompareTab } from './getCompareTab';
import { getManageTab } from './getManageTab';
import { getVersionsTab } from './getVersionsTab';

interface GetSourcesPageType {
  apiUrl: string;
  sourceID: string;
  parent: SceneAppPageLike;
}

export const getSourceDrilldown = ({ apiUrl, sourceID, parent }: GetSourcesPageType) =>
  new SceneAppPage({
    title: 'Source',
    renderTitle: () => SourceHeader({ sourceID }),
    key: 'sourceDrilldown',
    url: `${SOURCES_ROUTE}/${sourceID}`,
    routePath: SOURCE_DETAILS_ROUTE_PATH,
    getParentPage: () => parent,
    controls: [
      new SceneReactObject({
        component: () => SourceHeaderControls({ sourceID }),
      }),
    ],
    tabs: [getVersionsTab({ apiUrl, sourceID }), getCompareTab({ sourceID }), getManageTab({ sourceID })],
  });
