import { TypedDocumentNode, gql } from '@apollo/client';
import {
  GetCveListQuery,
  GetCveListQueryVariables,
  GetImpactedVersionListQuery,
  GetImpactedVersionListQueryVariables,
  GetSourceIssueCountsQuery,
  GetSourceIssueCountsQueryVariables,
  GetVersionIssueCountsQuery,
  GetVersionIssueCountsQueryVariables,
} from '__generated__/graphql';

export const GET_CVE_LIST: TypedDocumentNode<GetCveListQuery, GetCveListQueryVariables> = gql`
  query getCveList($filters: CveFilters, $first: Int, $after: Int) {
    cves(filters: $filters, first: $first, after: $after) {
      response {
        id
        cve
        title
      }
    }
  }
`;

export const GET_IMPACTED_VERSIONS_LIST: TypedDocumentNode<
  GetImpactedVersionListQuery,
  GetImpactedVersionListQueryVariables
> = gql`
  query getImpactedVersionList($id: ID!) {
    cve(id: $id) {
      id
      versions {
        id
        version
        source {
          id
          name
          type
          origin
        }
      }
    }
  }
`;

export const GET_VERSION_ISSUE_COUNTS: TypedDocumentNode<
  GetVersionIssueCountsQuery,
  GetVersionIssueCountsQueryVariables
> = gql`
  query getVersionIssueCounts($id: ID!) {
    version(id: $id) {
      id
      issues {
        id
        cveId
        isDismissed
      }
    }
  }
`;

export const GET_SOURCE_ISSUE_COUNTS: TypedDocumentNode<GetSourceIssueCountsQuery, GetSourceIssueCountsQueryVariables> =
  gql`
    query getSourceIssueCounts($id: ID!) {
      source(id: $id) {
        id
        versions {
          id
          issues {
            id
            cveId
            isDismissed
          }
        }
      }
    }
  `;
