import { useSuspenseQuery } from '@apollo/client';
import { Cve, GetCveReportFromExceptionQuery } from '__generated__/graphql';

import { GET_CVE_REPORT_FROM_EXCEPTION } from './CveReportQueries';
import { CveSummaryTable } from './CveSummaryTable';

interface CveSummaryTableFetcherExceptionIdProps {
  exceptionID: string;
}

export const CveSummaryTableFetcherExceptionId = ({ exceptionID }: CveSummaryTableFetcherExceptionIdProps) => {
  const { data } = useSuspenseQuery<GetCveReportFromExceptionQuery>(GET_CVE_REPORT_FROM_EXCEPTION, {
    variables: { id: exceptionID },
  });

  return <CveSummaryTable cve={data.cveException.cve as Cve} />;
};
