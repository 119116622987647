import { TypedDocumentNode, gql } from '@apollo/client';
import { GetVersionExceptionAlertQuery, GetVersionExceptionAlertQueryVariables } from '__generated__/graphql';

export const GET_VERSION_EXCEPTION_ALERT: TypedDocumentNode<
  GetVersionExceptionAlertQuery,
  GetVersionExceptionAlertQueryVariables
> = gql`
  query getVersionExceptionAlert($filters: CveExceptionFilters) {
    cveExceptions(filters: $filters) {
      metadata {
        totalCount
      }
    }
  }
`;
