import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Stack, Text, Box, Collapse, useStyles2, Tooltip } from '@grafana/ui';
import { capitalizeFirstLetter, getSourceImage } from 'components/utils';
import { useState } from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import { GITHUB_PREFIX } from 'shared/constants/routes/externalRoutes';

import { ExceptionFormType, ExceptionTargetType } from '../CreateException';

import { AllVersionsField } from './AllVersionsField';
import { VersionField } from './VersionField';

interface ImpactedFieldProps {
  control: Control<ExceptionFormType>;
  name: `targets.${number}`;
  sourceVersionMap: Record<string, ExceptionTargetType>;
}

export const ImpactedField = ({ control, name, sourceVersionMap }: ImpactedFieldProps) => {
  const styles = useStyles2(getStyles);
  const [isOpen, setIsOpen] = useState(false);
  const fieldValue = useWatch({ control, name });

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => {
        const { source, origin, type, allVersions, versions } = value;
        const sourceId = parseInt(source.value!, 10);
        const sourceImage = getSourceImage(origin);
        const versionOptions = sourceVersionMap[sourceId]?.versions || [];

        const handleAllVersionsChange = (checked: boolean) => {
          onChange({ ...value, allVersions: checked, versions: checked ? [] : versions });
        };

        const hasModified = fieldValue.allVersions || fieldValue.versions.length > 0;
        const isFirstItem = parseInt(name.split('.')[1], 10) === 0;

        return (
          <Collapse
            label={
              <Stack direction="row" width="100%" justifyContent={hasModified ? 'space-between' : 'flex-start'}>
                <Stack direction="row">
                  <Box display="inline" justifyContent="center" alignItems="center">
                    <img src={sourceImage} alt="logo" height="20px" width="20px" />
                  </Box>
                  <Text variant="h6">
                    {source.label!.replace(GITHUB_PREFIX, '')}{' '}
                    <Text color="secondary" weight="regular" italic>
                      ({capitalizeFirstLetter(type)})
                    </Text>
                  </Text>
                </Stack>
                {hasModified && (
                  <Box marginRight={1}>
                    <Tooltip content="This project has changes">
                      <Stack justifyContent="center">⚠️</Stack>
                    </Tooltip>
                  </Box>
                )}
              </Stack>
            }
            collapsible
            isOpen={isOpen}
            onToggle={() => setIsOpen(!isOpen)}
            className={styles.collapse(isFirstItem)}
          >
            <Stack direction="row">
              <VersionField
                control={control}
                name={`${name}.versions`}
                versionOptions={versionOptions}
                disabled={Boolean(allVersions)}
              />
              <AllVersionsField control={control} name={`${name}.allVersions`} onChange={handleAllVersionsChange} />
            </Stack>
          </Collapse>
        );
      }}
    />
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  collapse: (isFirstItem: boolean) =>
    css({
      marginBottom: theme.spacing(0),
      borderRadius: 0,
      ...(!isFirstItem && { borderTop: 'none' }),
    }),
});
