import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Stack, Tag, Text, TextLink, useStyles2 } from '@grafana/ui';
import { Cve } from '__generated__/graphql';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { CVES_ROUTE } from 'shared/constants/routes/appRoutes';

import { capitalizeFirstLetter, formatDate } from '../utils';

const getNVDLink = (cvssV3: string) =>
  `https://nvd.nist.gov/vuln-metrics/cvss/v3-calculator?vector=${cvssV3.replace(/CVSS:3\.[01]\//, '')}&version=${
    cvssV3.startsWith('CVSS:3.1') ? '3.1' : '3.0'
  }`;

interface CveSummaryTableType {
  cve: Cve;
}

export const CveSummaryTable = ({ cve }: CveSummaryTableType) => {
  const styles = useStyles2(getStyles);
  const navigate = useNavigate();

  const publishedDate = useMemo(
    () => (cve.publishedDate ? formatDate(new Date(cve.publishedDate)) : ''),
    [cve.publishedDate]
  );
  const lastModifiedDate = useMemo(
    () => (cve.lastModifiedDate ? formatDate(new Date(cve.lastModifiedDate)) : ''),
    [cve.lastModifiedDate]
  );
  const epssProbability = useMemo(
    () => (cve.epssProbability ? `${(cve.epssProbability * 100).toFixed(2)}%` : ''),
    [cve.epssProbability]
  );

  const { packages, scanners } = useMemo(() => getUniquePackagesAndScanners(cve), [cve]);

  return (
    <table className={styles.table}>
      <tbody>
        <TableRow label="Package(s)">
          <Stack direction="row" wrap="wrap">
            {packages.map((pkg, index) => (
              <Tag key={index} name={pkg} onClick={() => navigate(`${CVES_ROUTE}?var-package=${pkg}`)} />
            ))}
          </Stack>
        </TableRow>
        <TableRow label="Severity">
          <span
            className={
              styles.severity[cve.severity.toUpperCase() as keyof typeof styles.severity] || styles.defaultSeverity
            }
          >
            {cve.severity}
          </span>
        </TableRow>
        <TableRow label="CVSS">{cve.cvssScore}</TableRow>
        <TableRow label="EPSS Probability">
          {epssProbability ? (
            <Text>{epssProbability}</Text>
          ) : (
            <Text color="secondary" italic>
              Unknown
            </Text>
          )}
        </TableRow>
        {cve.cvssV3 && (
          <TableRow label="CVSS V3">
            <TextLink href={getNVDLink(cve.cvssV3)} inline={false} external>
              {cve.cvssV3}
            </TextLink>
          </TableRow>
        )}
        <TableRow label="CVE Published">
          {publishedDate ? (
            <Text>{publishedDate}</Text>
          ) : (
            <Text color="secondary" italic>
              Unknown
            </Text>
          )}
        </TableRow>
        <TableRow label="CVE Last Modified">
          {lastModifiedDate ? (
            <Text>{lastModifiedDate}</Text>
          ) : (
            <Text color="secondary" italic>
              Unknown
            </Text>
          )}
        </TableRow>
        <TableRow label="Scoring Authority">{cve.scoringAuthority}</TableRow>
        <TableRow label="Detected By">{scanners.map(capitalizeFirstLetter).join(', ')}</TableRow>
      </tbody>
    </table>
  );
};

const getUniquePackagesAndScanners = (cve: Cve) => {
  const packages = new Set<string>();
  const scanners = new Set<string>();

  cve.issues.forEach((issue) => {
    packages.add(issue.package);
    scanners.add(issue.tool.name);
  });

  return {
    packages: Array.from(packages),
    scanners: Array.from(scanners),
  };
};

const TableRow = ({ label, children, className }: { label: string; children: React.ReactNode; className?: string }) => (
  <tr>
    <th>{label}</th>
    <td className={className}>{children}</td>
  </tr>
);

const getStyles = (theme: GrafanaTheme2) => ({
  table: css({
    width: '100%',
    borderCollapse: 'collapse',
    border: `1px solid ${theme.colors.border.strong}`,
    background: theme.colors.background.canvas,
    'th, td': {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      borderBottom: `1px solid ${theme.colors.border.strong}`,
    },
    th: {
      textAlign: 'left',
      borderRight: `1px solid ${theme.colors.border.strong}`,
      fontWeight: theme.typography.fontWeightMedium,
    },
  }),
  severity: {
    CRITICAL: css({ color: theme.visualization.getColorByName('dark-red') }),
    HIGH: css({ color: theme.visualization.getColorByName('dark-orange') }),
    MEDIUM: css({ color: theme.visualization.getColorByName('dark-yellow') }),
    LOW: css({ color: theme.visualization.getColorByName('grey') }),
  },
  defaultSeverity: css({
    color: theme.colors.text.primary,
  }),
});
