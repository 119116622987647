import { useSuspenseQuery } from '@apollo/client';
import { Stack } from '@grafana/ui';
import { CveException, GetExceptionQuery } from '__generated__/graphql';
import { ExceptionComments } from 'components/CveExceptions/ExceptionComments';

import { ExceptionDescriptionCard } from './ExceptionDescriptionCard';
import { GET_EXCEPTION } from './ExceptionDetailsQueries';

interface ExceptionDetailsType {
  exceptionID: string;
}

export const ExceptionDetails = ({ exceptionID }: ExceptionDetailsType) => {
  const {
    data: { cveException },
  } = useSuspenseQuery<GetExceptionQuery>(GET_EXCEPTION, {
    variables: { id: exceptionID },
  });

  return (
    <Stack direction="column" gap={1} width="100%">
      <ExceptionDescriptionCard exception={cveException as CveException} />
      <ExceptionComments exceptionID={exceptionID} />
    </Stack>
  );
};
