import {
  EmbeddedScene,
  SceneAppPage,
  SceneAppPageLike,
  SceneFlexItem,
  SceneFlexLayout,
  SceneReactObject,
  SplitLayout,
} from '@grafana/scenes';
import { ExceptionDetailsHeader, ModifyExceptionControls } from 'components/CveExceptions';
import { ExceptionDetailsContainer } from 'components/CveExceptions/ExceptionDetails';
import { CveDescriptionContainer, CveSummaryTableContainer } from 'components/CveReport';
import { CVE_EXCEPTION_DETAILS_ROUTE, CVE_EXCEPTION_DETAILS_ROUTE_PATH } from 'shared/constants/routes/appRoutes';

interface GetCveExceptionDetailsDrilldownType {
  exceptionID: string;
  parent: SceneAppPageLike;
}

export const getCveExceptionDetailsDrilldown = ({ exceptionID, parent }: GetCveExceptionDetailsDrilldownType) =>
  new SceneAppPage({
    title: 'Exception Details',
    renderTitle: () => ExceptionDetailsHeader({ exceptionID }),
    key: 'exceptionDetailsDrilldown',
    url: `${CVE_EXCEPTION_DETAILS_ROUTE}/${exceptionID}`,
    routePath: CVE_EXCEPTION_DETAILS_ROUTE_PATH,
    getParentPage: () => parent,
    controls: [
      new SceneReactObject({
        component: () => ModifyExceptionControls({ exceptionID }),
      }),
    ],
    getScene: () => {
      return new EmbeddedScene({
        key: 'exceptionDetails',
        body: new SceneFlexLayout({
          direction: 'column',
          children: [
            new SceneFlexLayout({
              direction: 'column',
              height: '100%',
              children: [
                new SplitLayout({
                  initialSize: 0.65,
                  direction: 'row',
                  height: '100%',
                  primary: new SceneReactObject({
                    component: () => ExceptionDetailsContainer({ exceptionID }),
                  }),
                  secondary: new SceneFlexLayout({
                    direction: 'column',
                    children: [
                      new SceneFlexItem({
                        height: 'min-content',
                        body: new SceneReactObject({
                          component: () => CveSummaryTableContainer({ exceptionID }),
                        }),
                      }),
                      new SceneFlexItem({
                        body: new SceneReactObject({
                          component: () => CveDescriptionContainer({ exceptionID }),
                        }),
                      }),
                    ],
                  }),
                }),
              ],
            }),
          ],
        }),
      });
    },
  });
