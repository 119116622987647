import { PanelBuilders, SceneDataTransformer } from '@grafana/scenes';
import { TableCellDisplayMode, TableCellHeight, ThresholdsMode } from '@grafana/schema';
import { K8S_SCANNING_ROUTE } from 'shared/constants/routes/appRoutes';

export const getClusterCvesTable = () =>
  PanelBuilders.table()
    .setData(
      new SceneDataTransformer({
        transformations: [
          {
            id: 'organize',
            options: {
              indexByName: {
                Cluster: 0,
                Critical: 1,
                High: 2,
                Medium: 3,
                Low: 4,
              },
            },
          },
        ],
      })
    )
    .setDisplayMode('transparent')
    .setNoValue('No Clusters')
    .setHoverHeader(true)
    .setOption('cellHeight', TableCellHeight.Lg)
    .setOverrides((overridesBuilder) => {
      overridesBuilder.matchFieldsWithName('id').overrideCustomFieldConfig('hidden', true);

      overridesBuilder.matchFieldsWithName('Cluster').overrideLinks([
        {
          title: 'See details for ${__data.fields.Cluster}',
          url: `${K8S_SCANNING_ROUTE}/\${__data.fields.id}/namespaces`,
        },
      ]);

      overridesBuilder
        .matchFieldsWithName('Critical')
        .overrideCustomFieldConfig('width', 160)
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.ColorBackground,
        })
        .overrideThresholds({
          mode: ThresholdsMode.Absolute,
          steps: [
            { color: 'grey', value: -Infinity },
            { color: 'red', value: 1 },
          ],
        });

      overridesBuilder
        .matchFieldsWithName('High')
        .overrideCustomFieldConfig('width', 160)
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.ColorBackground,
        })
        .overrideThresholds({
          mode: ThresholdsMode.Absolute,
          steps: [
            { color: 'grey', value: -Infinity },
            { color: 'orange', value: 1 },
          ],
        });

      overridesBuilder
        .matchFieldsWithName('Medium')
        .overrideCustomFieldConfig('width', 160)
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.ColorBackground,
        })
        .overrideThresholds({
          mode: ThresholdsMode.Absolute,
          steps: [
            { color: 'grey', value: -Infinity },
            { color: 'yellow', value: 1 },
          ],
        });

      overridesBuilder
        .matchFieldsWithName('Low')
        .overrideCustomFieldConfig('width', 160)
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.ColorBackground,
        })
        .overrideThresholds({
          mode: ThresholdsMode.Absolute,
          steps: [
            { color: 'grey', value: -Infinity },
            { color: 'text', value: 1 },
          ],
        });
    })
    .build();
