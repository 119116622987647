import { SceneAppPage } from '@grafana/scenes';
import { TitleWithIcon } from 'components/TitleWithIcon';
import { CVES_ROUTE, CVES_ROUTE_PATH } from 'shared/constants/routes/appRoutes';

import { getCreateCveExceptionTab } from './getCreateCveExceptionTab';
import { getExploreCveExceptionsTab } from './getExploreCveExceptionsTab';
import { getExploreCvesTab } from './getExploreCvesTab';

interface GetCvesPageType {
  apiUrl: string;
}

export const getCvesPage = ({ apiUrl }: GetCvesPageType) =>
  new SceneAppPage({
    title: 'CVEs',
    renderTitle(title) {
      return TitleWithIcon({ title, icon: 'bug', color: 'error' });
    },
    subTitle: 'Common Vulnerabilities and Exposures',
    key: 'cvesPage',
    url: CVES_ROUTE,
    routePath: CVES_ROUTE_PATH,
    tabs: [getExploreCvesTab({ apiUrl }), getExploreCveExceptionsTab({ apiUrl }), getCreateCveExceptionTab({ apiUrl })],
  });
