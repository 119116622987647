import {
  SceneObjectState,
  SceneObjectBase,
  SceneComponentProps,
  sceneGraph,
  VariableDependencyConfig,
} from '@grafana/scenes';
import { K8sSummaryContainer } from 'components/K8sSummary';

interface K8sState extends SceneObjectState {
  cluster: string;
  namespace: string;
  image: string;
  tankaTeam: string;
  githubTeam: string;
  outOfSlo: string;
}

export class K8s extends SceneObjectBase<K8sState> {
  static Component = K8sRenderer;

  protected _variableDependency = new VariableDependencyConfig(this, {
    statePaths: ['cluster', 'namespace', 'image', 'tankaTeam', 'githubTeam', 'outOfSlo'],
  });
}

function K8sRenderer({ model }: SceneComponentProps<K8s>) {
  const { cluster, namespace, image, tankaTeam, githubTeam, outOfSlo } = model.useState();

  return (
    <K8sSummaryContainer
      cluster={sceneGraph.interpolate(model, cluster)}
      namespace={sceneGraph.interpolate(model, namespace)}
      image={sceneGraph.interpolate(model, image)}
      tankaTeam={sceneGraph.interpolate(model, tankaTeam)}
      githubTeam={sceneGraph.interpolate(model, githubTeam)}
      outOfSlo={sceneGraph.interpolate(model, outOfSlo)}
    />
  );
}
