import { EmbeddedScene, SceneAppPage, SceneFlexLayout, SceneReactObject } from '@grafana/scenes';
import { CreateException } from 'components/CveExceptions';
import { CVE_EXCEPTIONS_CREATE_ROUTE, CVE_EXCEPTIONS_CREATE_ROUTE_PATH } from 'shared/constants/routes/appRoutes';

interface GetCreateCveExceptionTabType {
  apiUrl: string;
}

export const getCreateCveExceptionTab = ({ apiUrl }: GetCreateCveExceptionTabType) => {
  return new SceneAppPage({
    title: 'Create an Exception',
    titleIcon: 'plus',
    key: 'createCveException',
    url: CVE_EXCEPTIONS_CREATE_ROUTE,
    routePath: CVE_EXCEPTIONS_CREATE_ROUTE_PATH,
    getScene: () => {
      return new EmbeddedScene({
        key: 'createCveExceptionTab',
        body: new SceneFlexLayout({
          direction: 'column',
          maxWidth: '100%',
          children: [
            new SceneReactObject({
              component: CreateException,
            }),
          ],
        }),
      });
    },
  });
};
