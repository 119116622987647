import { SceneAppPage, SceneReactObject } from '@grafana/scenes';
import { LinkButton } from '@grafana/ui';
import {
  CONFIGURE_SOURCE_ROUTE,
  CONFIGURE_SOURCE_ROUTE_PATH,
  PROJECTS_ROUTE,
  PROJECTS_ROUTE_PATH,
} from 'shared/constants/routes/appRoutes';
import { GRAFANA_LOGO_PNG } from 'shared/constants/routes/imageRoutes';

import { getConfigureSourceDrilldown } from './getConfigureSourceDrilldown';
import { getOverviewTab } from './getOverviewTab';
import { getPlanReleaseTab } from './getPlanReleaseTab';
import { getSourcesTab } from './getSourcesTab';

interface GetProjectsPageType {
  apiUrl: string;
}

export const getProjectsPage = ({ apiUrl }: GetProjectsPageType) =>
  new SceneAppPage({
    title: 'Projects',
    titleImg: GRAFANA_LOGO_PNG,
    key: 'projects',
    url: PROJECTS_ROUTE,
    routePath: PROJECTS_ROUTE_PATH,
    controls: [
      new SceneReactObject({
        component: () => (
          <LinkButton icon="plus" variant="success" size="sm" href={CONFIGURE_SOURCE_ROUTE}>
            Configure a New Source
          </LinkButton>
        ),
      }),
    ],
    tabs: [getOverviewTab({ apiUrl }), getSourcesTab({ apiUrl }), getPlanReleaseTab()],
    drilldowns: [
      {
        routePath: CONFIGURE_SOURCE_ROUTE_PATH,
        getPage(_, parent) {
          return getConfigureSourceDrilldown({ parent });
        },
      },
    ],
  });
