import { TypedDocumentNode, gql } from '@apollo/client';
import { GetFromK8sScanOnlyQuery, GetFromK8sScanOnlyQueryVariables } from '__generated__/graphql';

export const GET_FROM_K8S_SCAN_ONLY: TypedDocumentNode<GetFromK8sScanOnlyQuery, GetFromK8sScanOnlyQueryVariables> = gql`
  query getFromK8sScanOnly($id: ID!) {
    version(id: $id) {
      id
      fromK8sScan
      fromConfigScan
      k8sNamespaces {
        id
        cluster {
          id
          name
        }
      }
    }
  }
`;
