import { useSuspenseQuery } from '@apollo/client';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Icon, Stack, Text, Tooltip, useStyles2, useTheme2 } from '@grafana/ui';
import { CveException, GetCveExceptionsQuery } from '__generated__/graphql';
import { BaseTable } from 'components/base';
import { Direction, useSorting } from 'hooks/useSorting/useSorting';
import { memo, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { CVE_EXCEPTION_DETAILS_ROUTE } from 'shared/constants/routes/appRoutes';

import { GET_CVE_EXCEPTIONS } from './ExploreExceptionsQueries';
import { ExploreExceptionsRow } from './ExploreExceptionsRow';

interface ExploreExceptionsType {
  cve: string;
  source: string;
  version: string;
  reason: string;
  hideInactive: string;
}

const ExploreExceptions = memo(({ cve, source, version, reason, hideInactive }: ExploreExceptionsType) => {
  const styles = useStyles2(getStyles);
  const theme = useTheme2();
  const navigate = useNavigate();

  const { data } = useSuspenseQuery<GetCveExceptionsQuery>(GET_CVE_EXCEPTIONS, {
    variables: {
      filters: {
        cve,
        reason,
        sourceId: source,
        versionId: version,
        ...(hideInactive === '1' && { isActive: true }),
      },
    },
    fetchPolicy: 'network-only',
  });

  const exceptions = useMemo(() => data.cveExceptions.response, [data]);

  const { sortedData, handleSort, sortConfig } = useSorting(exceptions, 'createdOn');

  const renderSortIcon = (key: keyof CveException) => {
    if (sortConfig.key !== key) {
      return null;
    }
    return sortConfig.direction === Direction.Asc ? <Icon name="arrow-up" /> : <Icon name="arrow-down" />;
  };

  const handleNavigate = useCallback(
    (id: string) => {
      navigate(`${CVE_EXCEPTION_DETAILS_ROUTE}/${id}`);
    },
    [navigate]
  );

  return (
    <Stack direction="column">
      {sortedData.length ? (
        <BaseTable theme={theme}>
          <thead>
            <tr>
              <th>
                <Text>CVE </Text>
                <Tooltip
                  placement="right-end"
                  content={<Text element="p">Click on a CVE to view the exception details</Text>}
                >
                  <Icon name="info-circle" size="sm" />
                </Tooltip>
              </th>
              <th className={styles.summaryCol}>Summary</th>
              <th>Reason</th>
              <th className={styles.tagsCol}>Impacted Projects</th>
              <th className={styles.sortable} onClick={() => handleSort('createdOn')}>
                Created On {renderSortIcon('createdOn')}
              </th>
              <th className={styles.sortable} onClick={() => handleSort('expiresOn')}>
                <Text>Expires On </Text>
                <Tooltip
                  placement="right-end"
                  content={
                    <Text element="p">
                      Exceptions will be automatically marked as inactive after the expiration date and all affected
                      issues will be unhidden.
                    </Text>
                  }
                >
                  <Icon name="info-circle" size="sm" />
                </Tooltip>
                {renderSortIcon('expiresOn')}
              </th>
              <th className={styles.statusCol}>Status</th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((exception) => (
              <ExploreExceptionsRow
                key={exception.id}
                exception={exception as CveException}
                onNavigate={handleNavigate}
              />
            ))}
          </tbody>
        </BaseTable>
      ) : (
        <Text italic color="secondary">
          No CVE exceptions
        </Text>
      )}
    </Stack>
  );
});

ExploreExceptions.displayName = 'ExploreExceptions';

export { ExploreExceptions };

const getStyles = (theme: GrafanaTheme2) => ({
  summaryCol: css({
    width: '320px',
  }),
  tagsCol: css({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: theme.spacing(1),
    maxWidth: '400px',
  }),
  statusCol: css({
    width: '80px',
    textAlign: 'center',
  }),
  sortable: css({
    textAlign: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    minWidth: '180px',
    '&:hover': {
      textDecoration: 'underline',
    },
  }),
});
