import { TypedDocumentNode, gql } from '@apollo/client';
import { GetSourceHeaderQuery, GetSourceHeaderQueryVariables } from '__generated__/graphql';

export const GET_SOURCE_HEADER: TypedDocumentNode<GetSourceHeaderQuery, GetSourceHeaderQueryVariables> = gql`
  query getSourceHeader($id: ID!) {
    source(id: $id) {
      id
      name
      origin
      type
    }
  }
`;
