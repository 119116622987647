import { useLazyQuery } from '@apollo/client';
import { css } from '@emotion/css';
import { AppEvents, SelectableValue } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Box, Button, Divider, LoadingPlaceholder, Stack, Text, useStyles2 } from '@grafana/ui';
import { GetImpactedVersionListQuery } from '__generated__/graphql';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm, useFieldArray, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { CVE_EXCEPTIONS_ROUTE } from 'shared/constants/routes/appRoutes';

import { GET_IMPACTED_VERSIONS_LIST } from './CreateExceptionQueries';
import { ExceptionConfirmationModal } from './ExceptionConfirmationModal';
import { CveField, DescriptionField, GithubTeamField, ReasonField, ImpactedField } from './Fields';
import { mapVersionsToTargets } from './utils';

export type ExceptionTargetType = {
  source: SelectableValue<string>;
  versions: Array<SelectableValue<string>>;
  allVersions: boolean;
  origin: string;
  type: string;
};

export interface UpdateableExceptionFormFields {
  reason: string;
  description: string;
}

export interface ExceptionFormType extends UpdateableExceptionFormFields {
  cve: SelectableValue<string> | null;
  team: string;
  targets: ExceptionTargetType[];
}

export const CreateException = () => {
  const styles = useStyles2(getStyles);
  const navigate = useNavigate();

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [sourceVersionMap, setSourceVersionMap] = useState<Record<string, ExceptionTargetType>>({});
  const [exceptionFormData, setExceptionFormData] = useState<ExceptionFormType | null>(null);

  const { control, handleSubmit, reset, resetField, setValue, formState } = useForm<ExceptionFormType>({
    defaultValues: {
      cve: null,
      reason: '',
      team: '',
      description: '',
      targets: [],
    },
  });

  const cveValue = useWatch({ control, name: 'cve' });
  const targetsValue = useWatch({ control, name: 'targets' });

  const { fields } = useFieldArray({ control, name: 'targets' });

  const [getImpactedVersionList, { loading: loadingImpactedList }] = useLazyQuery<GetImpactedVersionListQuery>(
    GET_IMPACTED_VERSIONS_LIST,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted(data) {
        const { defaultTargets, allTargets } = mapVersionsToTargets(data.cve.versions);
        setValue('targets', Object.values(defaultTargets));
        setSourceVersionMap(allTargets);
      },
      onError: () => {
        getAppEvents().publish({
          type: AppEvents.alertError.name,
          payload: [`Error: failed to fetch impacted sources and versions`],
        });
      },
    }
  );

  useEffect(() => {
    if (cveValue) {
      resetField('targets');
      getImpactedVersionList({ variables: { id: cveValue.value } });
    }
  }, [cveValue, resetField, getImpactedVersionList]);

  const hasValidTarget = useMemo(() => {
    return targetsValue.some((target) => target.versions.length > 0 || target.allVersions);
  }, [targetsValue]);

  const onSubmit: SubmitHandler<ExceptionFormType> = (formData) => {
    setExceptionFormData(formData);
    setShowConfirmation(true);
  };

  const handleConfirm = useCallback(() => {
    reset();
    setShowConfirmation(false);
    navigate(CVE_EXCEPTIONS_ROUTE);
  }, [reset, navigate]);

  const renderImpactedVersions = useMemo(() => {
    if (!cveValue) {
      return <Text color="secondary">Select a CVE to see impacted versions.</Text>;
    }
    if (loadingImpactedList) {
      return <LoadingPlaceholder text="Loading..." />;
    }
    if (targetsValue.length === 0) {
      return <Text color="secondary">No impacted versions. Please try a different CVE.</Text>;
    }

    return (
      <Stack direction="column" gap={0}>
        <Box marginBottom={2}>
          <Text variant="h4">Impacted Projects</Text>
        </Box>
        {fields.map((field, index) => (
          <ImpactedField
            key={field.id}
            control={control}
            name={`targets.${index}`}
            sourceVersionMap={sourceVersionMap}
          />
        ))}
      </Stack>
    );
  }, [cveValue, loadingImpactedList, targetsValue, fields, control, sourceVersionMap]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <CveField control={control} formState={formState} required />
        <ReasonField control={control} formState={formState} required />
        <Divider />
        {renderImpactedVersions}
        <Divider />
        <GithubTeamField control={control} formState={formState} required />
        <DescriptionField control={control} formState={formState} required />
        <Button
          type="submit"
          variant="primary"
          size="md"
          disabled={!hasValidTarget}
          tooltip={!hasValidTarget ? 'Please select at least one impacted version' : undefined}
        >
          Preview Exception
        </Button>
      </form>

      {exceptionFormData && (
        <ExceptionConfirmationModal
          formData={exceptionFormData}
          isOpen={showConfirmation}
          onDismiss={() => setShowConfirmation(false)}
          onConfirm={handleConfirm}
        />
      )}
    </>
  );
};

const getStyles = () => ({
  form: css({
    maxWidth: '720px',
  }),
});
