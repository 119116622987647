import { Field, Label, Stack, Tooltip, Text, Icon, Input } from '@grafana/ui';
import { Control, Controller, FormState, Path } from 'react-hook-form';

import { ExceptionFormType } from '../CreateException';

interface GithubTeamFieldType<T extends ExceptionFormType> {
  control: Control<T>;
  formState: FormState<T>;
  required?: boolean;
  disabled?: boolean;
}

export const GithubTeamField = <T extends ExceptionFormType>({
  control,
  formState,
  required,
  disabled,
}: GithubTeamFieldType<T>) => {
  return (
    <Field
      label={
        <Label>
          <Stack alignItems="center">
            <span>Github Team{required ? '*' : ''}</span>
            <Tooltip
              placement="right-end"
              content={<Text element="p">Which team is responsible for this exception?</Text>}
            >
              <Icon name="info-circle" size="sm" />
            </Tooltip>
          </Stack>
        </Label>
      }
    >
      <Controller
        name={'team' as Path<T>}
        control={control}
        rules={{ required: true }}
        render={({ field: { ref, ...field } }) => <Input {...field} width={45} invalid={!!formState.errors.team} />}
      />
    </Field>
  );
};
