import { gql, TypedDocumentNode } from '@apollo/client';
import {
  GetIssueOwnersQuery,
  GetIssueOwnersQueryVariables,
  GetSourceOwnersQuery,
  GetSourceOwnersQueryVariables,
} from '__generated__/graphql';

export const GET_SOURCE_OWNERS: TypedDocumentNode<GetSourceOwnersQuery, GetSourceOwnersQueryVariables> = gql`
  query getSourceOwners($id: ID!) {
    source(id: $id) {
      id
      owners {
        id
        githubTeam
        isUser
      }
    }
  }
`;

export const GET_ISSUE_OWNERS: TypedDocumentNode<GetIssueOwnersQuery, GetIssueOwnersQueryVariables> = gql`
  query getIssueOwners($id: ID!) {
    issue(id: $id) {
      owners {
        id
        githubTeam
        isUser
      }
    }
  }
`;
