import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Box, LoadingPlaceholder, Stack, Text, useStyles2 } from '@grafana/ui';
import { capitalizeFirstLetter, getSourceImage } from 'components/utils';
import { Suspense } from 'react';
import { GITHUB_PREFIX } from 'shared/constants/routes/externalRoutes';

import { ExceptionTargetType } from './CreateException';
import { ExceptionSourceIssueCount } from './ExceptionSourceIssueCount';
import { ExceptionVersionIssueCount } from './ExceptionVersionIssueCount';

interface ExceptionConfirmationVersionRowProps {
  target: ExceptionTargetType;
  cveID: string;
}

export const ExceptionConfirmationVersionRow = ({ target, cveID }: ExceptionConfirmationVersionRowProps) => {
  const styles = useStyles2(getStyles);

  if (!target.allVersions && target.versions.length === 0) {
    return null;
  }

  const sourceImage = getSourceImage(target.origin);
  const label = target.source.label!.replace(GITHUB_PREFIX, '');
  const type = capitalizeFirstLetter(target.type);

  return (
    <Stack direction="column">
      <Stack direction="row">
        <Box display="inline" justifyContent="center" alignItems="center">
          <img src={sourceImage} alt="logo" height="20px" width="20px" />
        </Box>
        <Text variant="h6">
          {label}{' '}
          <Text color="secondary" weight="regular" italic>
            ({type})
          </Text>
        </Text>
      </Stack>
      <ul className={styles.list}>
        {target.allVersions && (
          <li>
            <Stack direction="row" alignItems="center" gap={1}>
              <Text color="error">All Versions</Text>
              <Suspense
                fallback={
                  <LoadingPlaceholder
                    className={styles.loading}
                    text={<Text color="secondary">counting issues</Text>}
                  />
                }
              >
                <ExceptionSourceIssueCount cveID={cveID} sourceID={target.source.value!} />
              </Suspense>
            </Stack>
          </li>
        )}
        {!target.allVersions &&
          target.versions.length > 0 &&
          target.versions.map((version, index) => {
            return (
              <li key={index}>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Text color="warning">{version.label!}</Text>
                  <Suspense
                    fallback={
                      <LoadingPlaceholder
                        className={styles.loading}
                        text={<Text color="secondary">counting issues</Text>}
                      />
                    }
                  >
                    <ExceptionVersionIssueCount cveID={cveID} versionID={version.value!} />
                  </Suspense>
                </Stack>
              </li>
            );
          })}
      </ul>
    </Stack>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  list: css({
    marginLeft: theme.spacing(8),
  }),
  loading: css({
    marginBottom: theme.spacing(0),
  }),
});
