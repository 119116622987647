import pluginJson from '../../../plugin.json';

export const PLUGIN_ID = pluginJson.id;

// Base Routes
export const PLUGIN_BASE_URL = `/a/${PLUGIN_ID}`;

export const MENU_ROUTE = PLUGIN_BASE_URL;
export const MENU_ROUTE_PATH = '';

// Projects Routes
export const PROJECTS_ROUTE = `${PLUGIN_BASE_URL}/projects`;
export const PROJECTS_ROUTE_PATH = `projects/*`;

export const OVERVIEW_ROUTE = `${PROJECTS_ROUTE}/overview`;
export const OVERVIEW_ROUTE_PATH = `overview/*`;

export const PLAN_RELEASE_ROUTE = `${PROJECTS_ROUTE}/plan-release`;
export const PLAN_RELEASE_ROUTE_PATH = `plan-release`;

export const CONFIGURE_SOURCE_ROUTE = `${PROJECTS_ROUTE}/configure-source`;
export const CONFIGURE_SOURCE_ROUTE_PATH = `configure-source`;

export const SOURCES_ROUTE = `${PROJECTS_ROUTE}/sources`;
export const SOURCES_ROUTE_PATH = `sources/*`;

export const SOURCE_DETAILS_ROUTE_PATH = `:sourceID/*`;
export const SOURCE_VERSIONS_ROUTE_PATH = `versions`;
export const SOURCE_COMPARE_ROUTE_PATH = `compare`;
export const SOURCE_MANAGE_ROUTE_PATH = `manage`;

export const VERSION_DETAILS_ROUTE_PATH = `version/:versionID/*`;
export const VERSION_VULNERABILITIES_ROUTE_PATH = `vulnerabilities`;
export const VERSION_SECRETS_ROUTE_PATH = `secrets`;
export const VERSION_LICENSES_ROUTE_PATH = `licenses`;

export const ISSUE_DETAILS_ROUTE_PATH = `issue/:issueID`;

// Kubernetes Routes
export const K8S_SCANNING_ROUTE = `${PLUGIN_BASE_URL}/k8s`;
export const K8S_SCANNING_ROUTE_PATH = `k8s/*`;

export const K8S_CLUSTER_DETAILS_ROUTE_PATH = `:clusterID/*`;
export const K8S_NAMESPACE_DETAILS_ROUTE_PATH = 'namespaces';
export const K8S_VERSION_DETAILS_ROUTE_PATH = 'versions';

// CVE Routes
export const CVES_ROUTE = `${PLUGIN_BASE_URL}/cves`;
export const CVES_ROUTE_PATH = `cves/*`;

export const CVE_DETAILS_ROUTE = `${CVES_ROUTE}/details`;
export const CVE_DETAILS_ROUTE_PATH = `details/:cve`;

export const CVE_EXCEPTIONS_ROUTE = `${CVES_ROUTE}/exceptions`;
export const CVE_EXCEPTIONS_ROUTE_PATH = `exceptions/*`;

export const CVE_EXCEPTIONS_CREATE_ROUTE = `${CVES_ROUTE}/create-exception`;
export const CVE_EXCEPTIONS_CREATE_ROUTE_PATH = `create-exception/*`;

export const CVE_EXCEPTION_DETAILS_ROUTE = `${CVE_EXCEPTIONS_ROUTE}/details`;
export const CVE_EXCEPTION_DETAILS_ROUTE_PATH = `details/:exceptionID`;

// Bug Bounty Routes
export const BUG_BOUNTY_ROUTE = `${PLUGIN_BASE_URL}/bug-bounty`;
export const BUG_BOUNTY_ROUTE_PATH = `bug-bounty/*`;

export const BUG_BOUNTY_REPORT_ROUTE = `${BUG_BOUNTY_ROUTE}/report`;
export const BUG_BOUNTY_REPORT_ROUTE_PATH = `report/:submissionCode`;

// Settings Routes
export const SETTINGS_ROUTE = `${PLUGIN_BASE_URL}/settings`;
export const SETTINGS_ROUTE_PATH = `settings/*`;

export const ACCESS_SETTINGS_ROUTE = `${SETTINGS_ROUTE}/access`;
export const ACCESS_SETTINGS_ROUTE_PATH = `access/*`;
