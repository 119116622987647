import {
  SceneObjectState,
  SceneObjectBase,
  SceneComponentProps,
  sceneGraph,
  VariableDependencyConfig,
} from '@grafana/scenes';
import { ExploreExceptionsContainer } from 'components/CveExceptions/ExploreExceptions';

interface CveExceptionsState extends SceneObjectState {
  cve: string;
  source: string;
  version: string;
  reason: string;
  hideInactive: string;
}

export class CveExceptions extends SceneObjectBase<CveExceptionsState> {
  static Component = CveExceptionsRenderer;

  protected _variableDependency = new VariableDependencyConfig(this, {
    statePaths: ['cve', 'source', 'version', 'reason', 'hideInactive'],
  });
}

function CveExceptionsRenderer({ model }: SceneComponentProps<CveExceptions>) {
  const { cve, source, version, reason, hideInactive } = model.useState();

  return (
    <ExploreExceptionsContainer
      cve={sceneGraph.interpolate(model, cve)}
      source={sceneGraph.interpolate(model, source)}
      version={sceneGraph.interpolate(model, version)}
      reason={sceneGraph.interpolate(model, reason)}
      hideInactive={sceneGraph.interpolate(model, hideInactive)}
    />
  );
}
