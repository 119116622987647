import { useQuery } from '@apollo/client';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Divider, Stack, Tag, Text, useStyles2 } from '@grafana/ui';
import { GetVersionNameQuery } from '__generated__/graphql';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { K8S_SCANNING_ROUTE } from 'shared/constants/routes/appRoutes';
import { GITHUB_PREFIX } from 'shared/constants/routes/externalRoutes';
import { K8S_LOGO_SVG } from 'shared/constants/routes/imageRoutes';

import { capitalizeFirstLetter, formatDate, getSourceImage } from '../utils';

import { GET_VERSION_NAME } from './VersionHeaderQueries';

interface VersionHeaderType {
  versionID: string;
}

export const VersionHeader = ({ versionID }: VersionHeaderType) => {
  const styles = useStyles2(getStyles);
  const { data, loading, error } = useQuery<GetVersionNameQuery>(GET_VERSION_NAME, {
    variables: {
      id: versionID,
    },
  });

  const navigate = useNavigate();

  if (loading || error) {
    return null;
  }

  const {
    version,
    latestScans,
    source: { name, origin },
  } = data!.version;
  const sourceImage = getSourceImage(origin);

  const { clusterMap } = (data?.version.k8sNamespaces || []).reduce(
    (acc: { clusterMap: Record<string, string> }, ns) => {
      if (!Object.keys(acc.clusterMap).includes(ns.cluster.name)) {
        acc.clusterMap[ns.cluster.name] = ns.cluster.id;
      }

      return acc;
    },
    { clusterMap: {} }
  );

  return (
    <Stack direction="column" gap={0.5} width="100%">
      <Stack justifyContent="flex-start" alignItems="center" gap={1}>
        <img src={sourceImage} alt="logo" height="30px" width="30px" />
        <Text element="h1" variant="h2">{`${name.replace(GITHUB_PREFIX, '')}:${version}`}</Text>
      </Stack>
      <Stack direction="row" gap={1}>
        {latestScans.map((scan, index) => (
          <Fragment key={scan.id}>
            {index > 0 && (
              <Text color="secondary" variant="bodySmall">
                |
              </Text>
            )}
            <Text color="secondary" variant="bodySmall">
              Scanned by {capitalizeFirstLetter(scan.tool.name)} on {formatDate(new Date(scan.ingestTime))}
            </Text>
          </Fragment>
        ))}
      </Stack>
      {Object.keys(clusterMap).length > 0 && (
        <div className={styles.k8s}>
          <Divider />
          <Stack direction="row" alignItems="center" wrap={true}>
            <img src={K8S_LOGO_SVG} alt="logo" height="20px" width="20px" aria-label="Kubernetes Scanning" />
            <Text>Running in Cluster{Object.keys(clusterMap).length === 1 ? '' : 's'}:</Text>
            {Object.entries(clusterMap).map(([name, id]) => (
              <Tag name={name} key={id} onClick={() => navigate(`${K8S_SCANNING_ROUTE}/${id}`)} />
            ))}
          </Stack>
        </div>
      )}
    </Stack>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  k8s: css({
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.colors.border.weak}`,
  }),
});
