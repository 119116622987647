import { PanelBuilders, SceneDataTransformer } from '@grafana/scenes';
import { FieldColorModeId, MappingType, TableCellDisplayMode, TableCellHeight, ThresholdsMode } from '@grafana/schema';
import { K8S_SCANNING_ROUTE } from 'shared/constants/routes/appRoutes';

export const getNamespaceCvesTable = (clusterID: string, hideEmpty: boolean) => {
  const emptyRowTransform = {
    id: 'filterByValue',
    options: {
      type: 'exclude',
      match: 'all',
      filters: [
        {
          fieldName: 'Critical',
          config: {
            id: 'lowerOrEqual',
            options: {
              value: 0,
            },
          },
        },
        {
          fieldName: 'High',
          config: {
            id: 'lowerOrEqual',
            options: {
              value: 0,
            },
          },
        },
        {
          fieldName: 'Medium',
          config: {
            id: 'lowerOrEqual',
            options: {
              value: 0,
            },
          },
        },
        {
          fieldName: 'Low',
          config: {
            id: 'lowerOrEqual',
            options: {
              value: 0,
            },
          },
        },
      ],
    },
  };
  const namespaceCvesTable = PanelBuilders.table()
    .setData(
      new SceneDataTransformer({
        transformations: [
          {
            id: 'organize',
            options: {
              indexByName: {
                Namespace: 0,
                Tanka: 1,
                Github: 2,
                Critical: 3,
                High: 4,
                Medium: 5,
                Low: 6,
              },
            },
          },
          ...(hideEmpty ? [emptyRowTransform] : []),
        ],
      })
    )
    .setDisplayMode('transparent')
    .setNoValue('No Namespaces')
    .setHoverHeader(true)
    .setOption('cellHeight', TableCellHeight.Lg)
    .setOverrides((overridesBuilder) => {
      overridesBuilder.matchFieldsWithName('id').overrideCustomFieldConfig('hidden', true);

      overridesBuilder.matchFieldsWithName('Namespace').overrideLinks([
        {
          title: 'See details for ${__data.fields.Namespace}',
          url: `${K8S_SCANNING_ROUTE}/${clusterID}/versions?var-namespace=\${__data.fields.id}&var-image=`,
        },
      ]);

      overridesBuilder
        .matchFieldsWithName('Critical')
        .overrideCustomFieldConfig('width', 160)
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.ColorBackground,
        })
        .overrideThresholds({
          mode: ThresholdsMode.Absolute,
          steps: [
            { color: 'grey', value: -Infinity },
            { color: 'red', value: 1 },
          ],
        });

      overridesBuilder
        .matchFieldsWithName('High')
        .overrideCustomFieldConfig('width', 160)
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.ColorBackground,
        })
        .overrideThresholds({
          mode: ThresholdsMode.Absolute,
          steps: [
            { color: 'grey', value: -Infinity },
            { color: 'orange', value: 1 },
          ],
        });

      overridesBuilder
        .matchFieldsWithName('Medium')
        .overrideCustomFieldConfig('width', 160)
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.ColorBackground,
        })
        .overrideThresholds({
          mode: ThresholdsMode.Absolute,
          steps: [
            { color: 'grey', value: -Infinity },
            { color: 'yellow', value: 1 },
          ],
        });

      overridesBuilder
        .matchFieldsWithName('Low')
        .overrideCustomFieldConfig('width', 160)
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.ColorBackground,
        })
        .overrideThresholds({
          mode: ThresholdsMode.Absolute,
          steps: [
            { color: 'grey', value: -Infinity },
            { color: 'text', value: 1 },
          ],
        });

      overridesBuilder
        .matchFieldsWithName('Tanka')
        .overrideDisplayName('Tanka Team')
        .overrideCustomFieldConfig('width', 160)
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.ColorText,
        })
        .overrideColor({
          mode: FieldColorModeId.Fixed,
          fixedColor: 'text',
        })
        .overrideMappings([
          {
            type: MappingType.ValueToText,
            options: {
              '': {
                text: 'No Team',
                index: 0,
                color: 'grey',
              },
            },
          },
        ])
        .overrideCustomFieldConfig('align', 'center');

      overridesBuilder
        .matchFieldsWithName('Github')
        .overrideDisplayName('GitHub Team')
        .overrideCustomFieldConfig('width', 160)
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.ColorText,
        })
        .overrideColor({
          mode: FieldColorModeId.Fixed,
          fixedColor: 'text',
        })
        .overrideMappings([
          {
            type: MappingType.ValueToText,
            options: {
              '': {
                text: 'No Team',
                index: 0,
                color: 'grey',
              },
            },
          },
        ])
        .overrideCustomFieldConfig('align', 'center');
    })
    .build();
  return namespaceCvesTable;
};
