import { useSuspenseQuery } from '@apollo/client';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Alert, Text, useStyles2 } from '@grafana/ui';
import { GetIssueReportQuery, Issue } from '__generated__/graphql';
import { Suspense } from 'react';

import { DaysToFix } from './DaysToFix';
import { IssuePackageBox } from './IssuePackageBox';
import { GET_ISSUE_REPORT } from './IssueReportQueries';
import { IssueReportTable } from './IssueReportTable';
import { IssueSummary } from './IssueSummary';
import { RelatedIssues } from './RelatedIssues';
import { SloTable } from './SloTable';

interface IssueReportType {
  issueID: string;
}

export const IssueReport = ({ issueID }: IssueReportType) => {
  const styles = useStyles2(getStyles);

  const {
    data: { issue },
  } = useSuspenseQuery<GetIssueReportQuery>(GET_ISSUE_REPORT, {
    variables: {
      id: issueID,
    },
  });

  return (
    <>
      {issue.slo === null && (
        <Alert title="This issue doesn't have an SLO!" severity="info">
          In order to start the timer for an SLO the issue must have <Text weight="bold">both</Text> a CVSS score and an
          available fix.{' '}
          {(!issue.cve.cvssScore || !issue.fixedVersion) && (
            <Text>
              This issue is missing{' '}
              {[!issue.cve.cvssScore && 'a CVSS score', !issue.fixedVersion && 'a fix'].filter(Boolean).join(' and ')}.
            </Text>
          )}
        </Alert>
      )}
      <div className={styles.upperGrid}>
        <IssuePackageBox issue={issue as Issue} />
        <Suspense>
          <DaysToFix sloRemaining={issue.sloRemaining} />
        </Suspense>
      </div>
      <div className={styles.grid}>
        <div>
          <IssueSummary description={issue.cve.description} url={issue.cve.url} cve={issue.cve.cve} />
          <Suspense>
            <RelatedIssues
              issueID={issueID}
              sourceID={issue.version.sourceId}
              versionID={issue.versionId}
              cve={issue.cve.cve}
            />
          </Suspense>
        </div>
        <div>
          <IssueReportTable issue={issue as Issue} />
          {issue.slo && (
            <Suspense>
              <SloTable slo={issue.slo} severity={issue.cve.severity} />
            </Suspense>
          )}
        </div>
      </div>
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  upperGrid: css({
    display: 'grid',
    gap: theme.spacing(2),
    gridTemplateColumns: 'auto minmax(auto, 240px)',
  }),
  grid: css({
    display: 'grid',
    gap: theme.spacing(2),
    gridTemplateColumns: '2fr 1fr',
  }),
});
