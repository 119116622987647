import { Source, Version } from '__generated__/graphql';
import { SOURCES_ROUTE } from 'shared/constants/routes/appRoutes';

export const getEntityDetails = (version: Version, source: Source) => {
  if (version) {
    return {
      name: `${version.source.name}:${version.version}`,
      origin: version.source.origin || '',
      route: `${SOURCES_ROUTE}/${version.source.id}/version/${version.id}`,
    };
  }
  return {
    name: source?.name || '',
    origin: source?.origin || '',
    route: `${SOURCES_ROUTE}/${source?.id}`,
  };
};

export const getExpirationColor = (daysUntilExpiration: number) => {
  if (daysUntilExpiration > 30) {
    return 'primary';
  }
  if (daysUntilExpiration > 14) {
    return 'warning';
  }

  return 'error';
};
