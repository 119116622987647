import { useMutation, useSuspenseQuery } from '@apollo/client';
import { css } from '@emotion/css';
import { AppEvents, GrafanaTheme2 } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { useStyles2, Select, ConfirmModal, Text, Box, Stack, Divider } from '@grafana/ui';
import { Comment, DeleteCveExceptionCommentMutation, GetCveExceptionCommentsQuery } from '__generated__/graphql';
import { CommentCard } from 'components/CommentCard';
import { useMemo, useState } from 'react';

import { DELETE_CVE_EXCEPTION_COMMENT } from './ExceptionCommentsMutations';
import { GET_CVE_EXCEPTION_COMMENTS } from './ExceptionCommentsQueries';

interface ExceptionCommentListProps {
  exceptionID: string;
}

export const ExceptionCommentList = ({ exceptionID }: ExceptionCommentListProps) => {
  const styles = useStyles2(getStyles);

  const [oldestFirst, setOldestFirst] = useState<boolean>(true);
  const [selectedComment, setSelectedComment] = useState<string | null>(null);

  const { data } = useSuspenseQuery<GetCveExceptionCommentsQuery>(GET_CVE_EXCEPTION_COMMENTS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: exceptionID,
    },
  });

  const [deleteComment] = useMutation<DeleteCveExceptionCommentMutation>(DELETE_CVE_EXCEPTION_COMMENT, {
    refetchQueries: [GET_CVE_EXCEPTION_COMMENTS],
    ignoreResults: true,
    onError: () => {
      getAppEvents().publish({
        type: AppEvents.alertError.name,
        payload: [`Error: failed to delete comment`],
      });
    },
  });

  const sortedComments = useMemo(() => {
    const comments = data?.cveException?.comments || [];
    return sortComments(comments, oldestFirst);
  }, [data?.cveException?.comments, oldestFirst]);

  const handleDeleteComment = () => {
    if (selectedComment) {
      deleteComment({ variables: { input: { exceptionId: exceptionID, commentId: selectedComment } } });
      setSelectedComment(null);
    }
  };

  return (
    <>
      <Divider />
      <Stack justifyContent="space-between" alignItems="center">
        <Text variant="h3">Comments</Text>
        <Select
          options={[
            { label: 'Oldest First', value: true },
            { label: 'Newest First', value: false },
          ]}
          value={oldestFirst}
          defaultValue={{ label: 'Oldest First', value: true }}
          onChange={(option) => setOldestFirst(option.value!)}
          width={20}
        />
      </Stack>

      {sortedComments.length > 0 ? (
        <ul className={styles.comments}>
          {sortedComments.map((comment) => (
            <li key={comment.id}>
              <CommentCard
                userName={comment.userName}
                dateString={comment.date}
                comment={comment.comment}
                onDelete={() => setSelectedComment(comment.id)}
                gravatarUrl={comment.gravatarUrl}
              />
            </li>
          ))}
        </ul>
      ) : (
        <Box display="flex" justifyContent="center" marginY={1.5}>
          <Text italic color="secondary">
            No comments
          </Text>
        </Box>
      )}

      <ConfirmModal
        isOpen={Boolean(selectedComment)}
        title="Delete Comment?"
        body="Are you sure you'd like to delete this comment?"
        confirmText="Delete"
        onConfirm={handleDeleteComment}
        onDismiss={() => setSelectedComment(null)}
      />
    </>
  );
};

const sortComments = (comments: Comment[], oldestFirst: boolean) =>
  oldestFirst
    ? [...comments].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    : [...comments].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

const getStyles = (theme: GrafanaTheme2) => ({
  comments: css({
    marginTop: theme.spacing(2),
    listStyleType: 'none',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  }),
});
