import { css, cx } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Field, Label, Stack, Tooltip, Text, Icon, ReactMonacoEditor, useStyles2 } from '@grafana/ui';
import { Control, Controller, FormState, Path } from 'react-hook-form';

import { UpdateableExceptionFormFields } from '../CreateException';

interface DescriptionFieldType<T extends UpdateableExceptionFormFields> {
  control: Control<T>;
  formState: FormState<T>;
  required?: boolean;
}

export const DescriptionField = <T extends UpdateableExceptionFormFields>({
  control,
  formState,
  required,
}: DescriptionFieldType<T>) => {
  const styles = useStyles2(getStyles);

  return (
    <Field
      label={
        <Label>
          <Stack alignItems="center">
            <span>Description{required ? '*' : ''}</span>
            <Tooltip
              placement="right-end"
              content={<Text element="p">Briefly describe why the exception is needed</Text>}
            >
              <Icon name="info-circle" size="sm" />
            </Tooltip>
          </Stack>
        </Label>
      }
    >
      <Controller
        name={'description' as Path<T>}
        control={control}
        rules={{ required }}
        render={({ field: { ref, ...field } }) => (
          <ReactMonacoEditor
            {...field}
            language="markdown"
            className={cx(styles.editor, { [styles.invalid]: !!formState.errors.description })}
            options={{
              overviewRulerLanes: 0,
              wordWrap: 'on',
              renderLineHighlight: 'none',
              folding: false,
              lineNumbers: 'off',
              lineDecorationsWidth: 8,
              fontSize: 14,
              minimap: { enabled: false },
              scrollbar: { vertical: 'hidden' },
            }}
          />
        )}
      />
    </Field>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  editor: css({
    background: theme.colors.background.canvas,
    border: `1px solid ${theme.colors.border.medium}`,
    padding: '4px 0px',
    resize: 'vertical',
    overflow: 'auto',
    height: '160px',
  }),
  invalid: css({
    border: `1px solid ${theme.colors.error.border}`,
  }),
});
