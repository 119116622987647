import { useSuspenseQuery } from '@apollo/client';
import { Cve, GetCveReportFromExceptionQuery } from '__generated__/graphql';

import { CveDescription } from './CveDescription';
import { GET_CVE_REPORT_FROM_EXCEPTION } from './CveReportQueries';

interface CveDescriptionFetcherExceptionIdProps {
  exceptionID: string;
}

export const CveDescriptionFetcherExceptionId = ({ exceptionID }: CveDescriptionFetcherExceptionIdProps) => {
  const { data } = useSuspenseQuery<GetCveReportFromExceptionQuery>(GET_CVE_REPORT_FROM_EXCEPTION, {
    variables: { id: exceptionID },
  });

  return <CveDescription cve={data.cveException.cve as Cve} />;
};
