import { SceneQueryRunner } from '@grafana/scenes';
import { createInfinityQuery, getInfinityDatasource } from 'datasources/infinity';

export const getClusterCveCountsQueryRunner = (apiUrl: string, clusterID: string) =>
  new SceneQueryRunner({
    datasource: getInfinityDatasource(),
    queries: [
      createInfinityQuery({
        refId: 'A',
        apiUrl,
        queryBody: `
          query getClusterCveCounts($id: ID!, $filters: K8sClusterFilters) {
            k8sCluster(id: $id, filters: $filters) {
              id
              totalCveCounts {
                critical
                high
                medium
                low
              }
            }
          }
        `,
        variables: {
          id: clusterID,
          filters: {
            tankaTeam: '${tankaTeam}',
            githubTeam: '${githubTeam}',
            outOfSlo: '${outOfSlo}',
          },
        },
        overrides: {
          base: {
            root_selector: 'data.k8sCluster.totalCveCounts',
          },
        },
      }),
    ],
  });
