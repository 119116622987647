import { ExceptionTargetType } from './CreateException';

export const mapVersionsToTargets = (versions: any[]) => {
  const allTargets: Record<string, ExceptionTargetType> = {};

  versions.forEach(({ id: versionId, version: versionTag, source }) => {
    const { id: sourceId, name: sourceName, origin, type } = source;

    if (!allTargets[sourceId]) {
      allTargets[sourceId] = {
        source: { label: sourceName, value: sourceId.toString() },
        versions: [],
        allVersions: false,
        origin,
        type,
      };
    }

    if (!allTargets[sourceId].versions.some((v) => v.value === versionId.toString())) {
      allTargets[sourceId].versions.push({ label: versionTag, value: versionId.toString() });
    }
  });

  // Create defaultTargets by emptying versions from allTargets
  const defaultTargets = Object.keys(allTargets).reduce<Record<string, ExceptionTargetType>>((acc, sourceId) => {
    acc[sourceId] = {
      ...allTargets[sourceId],
      versions: [],
    };
    return acc;
  }, {});

  return { defaultTargets, allTargets };
};
