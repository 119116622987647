import {
  CustomVariable,
  EmbeddedScene,
  QueryVariable,
  SceneAppPage,
  SceneFlexItem,
  SceneFlexLayout,
  SceneVariableSet,
  VariableValueSelectors,
} from '@grafana/scenes';
import { VariableHide } from '@grafana/schema';
import { Icon } from '@grafana/ui';
import { EXCEPTION_REASONS } from 'components/CveExceptions/CreateException/Fields/ReasonField';
import { SwitchVariable } from 'controls/SwitchVariable';
import { TextInputVariable } from 'controls/TextInputVariable';
import { CveExceptions } from 'customScenes/CveExceptions';
import { createInfinityVariable, getInfinityDatasource } from 'datasources/infinity';
import {
  CVE_EXCEPTIONS_ROUTE,
  CVE_EXCEPTIONS_ROUTE_PATH,
  CVE_EXCEPTION_DETAILS_ROUTE_PATH,
} from 'shared/constants/routes/appRoutes';

import { getCveExceptionDetailsDrilldown } from './getCveExceptionDetailsDrilldown';

interface GetExploreCveExceptionsTabType {
  apiUrl: string;
}

export const getExploreCveExceptionsTab = ({ apiUrl }: GetExploreCveExceptionsTabType) => {
  const cveVariable = new TextInputVariable({
    name: 'cve',
    prefix: <Icon name="search" />,
    placeholder: 'Search CVEs',
    width: 35,
    hide: VariableHide.hideLabel,
  });

  const reasonVariable = new CustomVariable({
    name: 'reason',
    label: 'Exception Reason',
    value: 'All',
    text: 'All',
    query: `All,${Object.values(EXCEPTION_REASONS).join(',')}`,
  });

  const sourceVariable = new QueryVariable({
    name: 'source',
    label: 'Source',
    includeAll: true,
    defaultToAll: true,
    allValue: '0',
    value: '$__all',
    datasource: getInfinityDatasource(),
    query: createInfinityVariable({
      refId: 'variable-source',
      apiUrl,
      queryBody: `
        query getSources {
          sources {
            response {
              __value: id
              __text: name
            }
          }
        }
      `,
      overrides: {
        base: {
          root_selector: 'data.sources.response',
        },
      },
    }),
  });

  const versionVariable = new QueryVariable({
    name: 'version',
    label: 'Version',
    includeAll: true,
    defaultToAll: true,
    allValue: '0',
    value: '$__all',
    datasource: getInfinityDatasource(),
    query: createInfinityVariable({
      refId: 'variable-version',
      apiUrl,
      queryBody: `
        query getVersions($id: ID!) {
          source(id: $id) {
              versions {
                __value: id
                __text: version
              }
          }
        }
      `,
      variables: {
        id: '$source',
      },
      overrides: {
        base: {
          root_selector: 'data.source.versions',
        },
      },
    }),
  });

  const hideInactiveVariable = new SwitchVariable({
    name: 'hideInactive',
    label: 'Hide Inactive Exceptions',
    description: 'Hide exceptions that have expired or been marked as inactive',
    value: '1',
  });

  return new SceneAppPage({
    title: 'Explore CVE Exceptions',
    titleIcon: 'exclamation-triangle',
    key: 'cveExceptionsDrilldown',
    url: CVE_EXCEPTIONS_ROUTE,
    routePath: CVE_EXCEPTIONS_ROUTE_PATH,
    getScene: () => {
      return new EmbeddedScene({
        key: 'exploreExceptionsDrilldown',
        $variables: new SceneVariableSet({
          variables: [cveVariable, reasonVariable, sourceVariable, versionVariable, hideInactiveVariable],
        }),
        body: new SceneFlexLayout({
          direction: 'column',
          maxWidth: '100%',
          children: [
            new SceneFlexItem({
              height: '100%',
              body: new CveExceptions({
                cve: '${cve}',
                source: '${source}',
                version: '${version}',
                reason: '${reason}',
                hideInactive: '${hideInactive}',
              }),
            }),
          ],
        }),
        controls: [new VariableValueSelectors({})],
      });
    },
    drilldowns: [
      {
        routePath: CVE_EXCEPTION_DETAILS_ROUTE_PATH,
        getPage(routeMatch, parent) {
          const exceptionID = routeMatch.params.exceptionID;
          return getCveExceptionDetailsDrilldown({ exceptionID, parent });
        },
      },
    ],
  });
};
