import { TypedDocumentNode, gql } from '@apollo/client';
import {
  DeleteCveExceptionMutation,
  DeleteCveExceptionMutationVariables,
  UpdateCveExceptionStatusMutation,
  UpdateCveExceptionStatusMutationVariables,
} from '__generated__/graphql';

export const UPDATE_CVE_EXCEPTION_STATUS: TypedDocumentNode<
  UpdateCveExceptionStatusMutation,
  UpdateCveExceptionStatusMutationVariables
> = gql`
  mutation updateCveExceptionStatus($input: UpdateCveExceptionInput!) {
    updateCveException(input: $input) {
      id
      isActive
    }
  }
`;

export const DELETE_CVE_EXCEPTION: TypedDocumentNode<DeleteCveExceptionMutation, DeleteCveExceptionMutationVariables> =
  gql`
    mutation deleteCveException($input: DeleteCveExceptionInput!) {
      deleteCveException(input: $input)
    }
  `;
