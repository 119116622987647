import { GITHUB_PREFIX } from 'shared/constants/routes/externalRoutes';

const reAtSymbol = /^@/;

export const constructGitHubUrl = (handle: string, isUser: boolean): string | undefined => {
  if (!handle) {
    return undefined;
  }
  if (isUser) {
    return `${GITHUB_PREFIX}${handle.replace(reAtSymbol, '')}`;
  }
  const [org, repo] = handle.split('/');
  return `${GITHUB_PREFIX}orgs/${org.replace(reAtSymbol, '')}/teams/${repo}`;
};
