import { SceneQueryRunner } from '@grafana/scenes';
import { createInfinityQuery, getInfinityDatasource } from 'datasources/infinity';

export const getTotalCveCountsQueryRunner = (apiUrl: string) =>
  new SceneQueryRunner({
    datasource: getInfinityDatasource(),
    queries: [
      createInfinityQuery({
        refId: 'A',
        apiUrl,
        queryBody: `
          query getSummary($filters: SummaryFilters) {
            summary(filters: $filters) {
              totalCveCounts {
                critical
                high
                medium
                low
              }
            }
          }
        `,
        variables: {
          filters: {
            groupId: '$group',
            latestOnly: '$latestOnly',
            onlyIssuesWithSlo: '$onlyIssuesWithSlo',
            fromConfigScanOnly: '$excludeK8sSources',
            outOfSlo: '$outOfSlo',
          },
        },
        overrides: {
          base: {
            root_selector: 'data.summary.totalCveCounts',
          },
        },
      }),
    ],
  });
