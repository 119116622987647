import { TypedDocumentNode, gql } from '@apollo/client';
import { CreateCveExceptionMutation, CreateCveExceptionMutationVariables } from '__generated__/graphql';

export const CREATE_CVE_EXCEPTION: TypedDocumentNode<CreateCveExceptionMutation, CreateCveExceptionMutationVariables> =
  gql`
    mutation createCveException($input: CreateCveExceptionInput!) {
      createCveException(input: $input) {
        id
      }
    }
  `;
