import { useSuspenseQuery } from '@apollo/client';
import { Cve, GetCveReportQuery } from '__generated__/graphql';

import { CveDescription } from './CveDescription';
import { GET_CVE_REPORT } from './CveReportQueries';

interface CveDescriptionFetcherCveProps {
  cve: string;
}

export const CveDescriptionFetcherCve = ({ cve }: CveDescriptionFetcherCveProps) => {
  const { data } = useSuspenseQuery<GetCveReportQuery>(GET_CVE_REPORT, {
    variables: {
      filters: {
        cve,
      },
      first: 1,
    },
  });

  const cveData = data.cves.response[0];

  return <CveDescription cve={cveData as Cve} />;
};
