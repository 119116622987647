import { EmbeddedScene, SceneAppPage, SceneFlexLayout, SceneReactObject } from '@grafana/scenes';
import { AccessControl } from 'components/AccessControl';
import { ACCESS_SETTINGS_ROUTE, ACCESS_SETTINGS_ROUTE_PATH } from 'shared/constants/routes/appRoutes';

interface GetAccessTabType {
  apiUrl: string;
}

export const getAccessTab = ({ apiUrl }: GetAccessTabType) =>
  new SceneAppPage({
    title: 'Access',
    titleIcon: 'users-alt',
    key: 'accessTab',
    url: ACCESS_SETTINGS_ROUTE,
    routePath: ACCESS_SETTINGS_ROUTE_PATH,
    getScene: () => {
      return new EmbeddedScene({
        key: 'manage',
        body: new SceneFlexLayout({
          direction: 'column',
          children: [
            new SceneReactObject({
              component: () => <AccessControl />,
            }),
          ],
        }),
      });
    },
  });
