import { TypedDocumentNode, gql } from '@apollo/client';
import {
  CreateCveExceptionCommentMutation,
  CreateCveExceptionCommentMutationVariables,
  DeleteCveExceptionCommentMutation,
  DeleteCveExceptionCommentMutationVariables,
} from '__generated__/graphql';

export const CREATE_CVE_EXCEPTION_COMMENT: TypedDocumentNode<
  CreateCveExceptionCommentMutation,
  CreateCveExceptionCommentMutationVariables
> = gql`
  mutation createCveExceptionComment($input: CreateCveExceptionCommentInput!) {
    createCveExceptionComment(input: $input) {
      id
    }
  }
`;

export const DELETE_CVE_EXCEPTION_COMMENT: TypedDocumentNode<
  DeleteCveExceptionCommentMutation,
  DeleteCveExceptionCommentMutationVariables
> = gql`
  mutation deleteCveExceptionComment($input: DeleteCveExceptionCommentInput!) {
    deleteCveExceptionComment(input: $input)
  }
`;
