import { useMutation } from '@apollo/client';
import { AppEvents } from '@grafana/data';
import { config, getAppEvents } from '@grafana/runtime';
import { Button, Modal } from '@grafana/ui';
import { CveException, ExceptionDetailsUpdateCveExceptionMutation } from '__generated__/graphql';
import { UpdateableExceptionFormFields } from 'components/CveExceptions/CreateException/CreateException';
import { DescriptionField, ReasonField } from 'components/CveExceptions/CreateException/Fields';
import { GET_CVE_EXCEPTION_COMMENTS } from 'components/CveExceptions/ExceptionComments/ExceptionCommentsQueries';
import { SubmitHandler, useForm } from 'react-hook-form';

import { EXCEPTION_DETAILS_UPDATE_CVE_EXCEPTION } from './ExceptionDetailsMutations';
import { GET_EXCEPTION } from './ExceptionDetailsQueries';

interface EditExceptionModalProps {
  isOpen: boolean;
  exception: CveException;
  onDismiss: () => void;
  onSuccess: () => void;
}

export const EditExceptionModal = ({ isOpen, onDismiss, onSuccess, exception }: EditExceptionModalProps) => {
  const { control, handleSubmit, reset, formState } = useForm<UpdateableExceptionFormFields>({
    defaultValues: {
      reason: exception.reason,
      description: exception.description,
    },
  });

  const [updateCveException] = useMutation<ExceptionDetailsUpdateCveExceptionMutation>(
    EXCEPTION_DETAILS_UPDATE_CVE_EXCEPTION,
    {
      ignoreResults: true,
      onError: () => {
        getAppEvents().publish({
          type: AppEvents.alertError.name,
          payload: [`Error: failed to update CVE exception`],
        });
      },
      onCompleted: () => {
        getAppEvents().publish({
          type: AppEvents.alertSuccess.name,
          payload: [`success: exception updated`],
        });
        onSuccess();
      },
      refetchQueries: [GET_EXCEPTION, GET_CVE_EXCEPTION_COMMENTS],
    }
  );

  const onSubmit: SubmitHandler<UpdateableExceptionFormFields> = (formData) => {
    updateCveException({
      variables: {
        input: {
          exceptionId: exception.id,
          reason: formData.reason,
          description: formData.description,
          userId: user.id,
          userName: user.name,
          gravatarUrl: user.gravatarUrl,
        },
      },
    });
    onDismiss();
    reset();
  };

  const user = config.bootData.user;

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} title="Update Exception">
      <form onSubmit={handleSubmit(onSubmit)}>
        <ReasonField control={control} formState={formState} />
        <DescriptionField control={control} formState={formState} />
        <Modal.ButtonRow>
          <Button variant="secondary" fill="outline" size="sm" onClick={onDismiss}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            size="sm"
            disabled={!formState.isDirty || !user.isSignedIn}
            tooltip={!user.isSignedIn ? 'Sign in to update the exception' : ''}
          >
            Update Exception
          </Button>
        </Modal.ButtonRow>
      </form>
    </Modal>
  );
};
