import { SelectableValue } from '@grafana/data';
import { Field, Label, MultiSelect, Tooltip, Icon, Text, Stack } from '@grafana/ui';
import { Control, Controller, Path } from 'react-hook-form';

import { ExceptionFormType } from '../CreateException';

interface VersionFieldProps {
  control: Control<ExceptionFormType>;
  name: Path<ExceptionFormType>;
  required?: boolean;
  versionOptions: SelectableValue[];
  invalid?: boolean;
  disabled?: boolean;
}

export const VersionField = ({ control, required, versionOptions, name, invalid, disabled }: VersionFieldProps) => {
  return (
    <Field
      label={
        <Label>
          <Stack alignItems="center">
            <span>Versions{required ? '*' : ''}</span>
            <Tooltip
              placement="right-end"
              content={<Text element="p">Select specific versions to apply the exception to</Text>}
            >
              <Icon name="info-circle" size="sm" />
            </Tooltip>
          </Stack>
        </Label>
      }
    >
      <Controller
        name={name}
        control={control}
        rules={{ required }}
        render={({ field: { value, onChange } }) => (
          <MultiSelect
            value={disabled ? [{ label: 'All', value: 'all' }] : value}
            onChange={onChange}
            virtualized
            placeholder={disabled ? 'All' : 'Select versions'}
            options={versionOptions}
            width={40}
            invalid={invalid}
            noOptionsMessage="No available versions"
            disabled={disabled}
          />
        )}
      />
    </Field>
  );
};
