import { AppPlugin } from '@grafana/data';

import { AppProvider, AppConfig } from './app';

export const plugin = new AppPlugin<{}>().setRootPage(AppProvider).addConfigPage({
  title: 'Configuration',
  icon: 'cog',
  body: AppConfig,
  id: 'configuration',
});
