import { useSuspenseQuery } from '@apollo/client';
import { Text } from '@grafana/ui';
import { GetSourceIssueCountsQuery } from '__generated__/graphql';

import { GET_SOURCE_ISSUE_COUNTS } from './CreateExceptionQueries';

interface ExceptionSourceIssueCountProps {
  cveID: string;
  sourceID: string;
}

export const ExceptionSourceIssueCount = ({ cveID, sourceID }: ExceptionSourceIssueCountProps) => {
  const { data } = useSuspenseQuery<GetSourceIssueCountsQuery>(GET_SOURCE_ISSUE_COUNTS, {
    variables: { id: sourceID },
  });

  const count = data.source?.versions.reduce((acc, version) => {
    return acc + version.issues.filter((issue) => issue.cveId === cveID && !issue.isDismissed).length;
  }, 0);

  return (
    <Text color="secondary" italic>
      (dismisses {count} issue{count === 1 ? '' : 's'})
    </Text>
  );
};
