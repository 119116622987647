import { useQuery } from '@apollo/client';
import { Icon, Stack, Text, useTheme2 } from '@grafana/ui';
import { GetCveHeaderQuery } from '__generated__/graphql';

import { GET_CVE_HEADER } from './CveHeaderQueries';

interface CveHeaderType {
  cve: string;
}

export const CveHeader = ({ cve }: CveHeaderType) => {
  const theme = useTheme2();

  const { data, loading, error } = useQuery<GetCveHeaderQuery>(GET_CVE_HEADER, {
    variables: {
      filters: {
        cve,
      },
      first: 1,
    },
  });

  if (loading || error) {
    return null;
  }

  const { cve: cveNumber, title } = data!.cves.response[0];
  const capitalTitle = title.charAt(0).toUpperCase() + title.slice(1);

  return (
    <Stack direction="column" gap={1}>
      <Stack direction="row" alignItems="center">
        <Icon name="bug" width="32" height="32" color={theme.colors.error.main} />
        <Text element="h1">{cveNumber}</Text>
      </Stack>
      <Text element="h4" color="secondary">
        {capitalTitle}
      </Text>
    </Stack>
  );
};
