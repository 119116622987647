import { useMutation } from '@apollo/client';
import { AppEvents } from '@grafana/data';
import { config, getAppEvents } from '@grafana/runtime';
import { Box, Modal, Button, Text, Stack, Divider } from '@grafana/ui';
import { CreateCveExceptionMutation, CreateCveExceptionMutationVariables } from '__generated__/graphql';

import type { ExceptionFormType } from './CreateException';
import { CREATE_CVE_EXCEPTION } from './CreateExceptionMutations';
import { ExceptionConfirmationVersionRow } from './ExceptionConfirmationVersionRow';

interface ConfirmationModalType {
  formData: ExceptionFormType;
  isOpen: boolean;
  onDismiss: () => void;
  onConfirm: () => void;
}

export const ExceptionConfirmationModal = ({ formData, isOpen, onDismiss, onConfirm }: ConfirmationModalType) => {
  const [addCveException, { loading }] = useMutation<CreateCveExceptionMutation>(CREATE_CVE_EXCEPTION, {
    ignoreResults: true,
    onError: () => {
      getAppEvents().publish({
        type: AppEvents.alertError.name,
        payload: [`Error: failed to create CVE exception`],
      });
    },
    onCompleted: () => {
      getAppEvents().publish({
        type: AppEvents.alertSuccess.name,
        payload: [`Success: created new CVE exception`],
      });
    },
  });

  const user = config.bootData.user;

  const onSubmit = () => {
    const { cve, reason, team, description, targets } = formData;

    const payload: CreateCveExceptionMutationVariables['input'] = {
      cveId: cve!.value!,
      reason,
      team,
      description,
      sourceIds: [],
      versionIds: [],
    };

    targets.forEach((target) => {
      if (target.allVersions) {
        payload.sourceIds.push(target.source.value!);
      }
      if (target.versions.length > 0) {
        target.versions.forEach((version) => {
          payload.versionIds.push(version.value!);
        });
      }
    });

    addCveException({ variables: { input: payload } });
    onConfirm();
  };

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} title={`⚠️ New Exception: ${formData.cve!.label}`}>
      <Stack direction="column">
        <Stack direction="row" gap={1} alignItems="baseline">
          <Text element="h5" weight="bold">
            Reason:
          </Text>
          <Text color="secondary" variant="h5" weight="regular">
            {formData.reason}
          </Text>
        </Stack>
        <Stack direction="row" gap={1} alignItems="baseline">
          <Text element="h5" weight="bold">
            Team:
          </Text>
          <Text color="secondary" variant="h5" weight="regular">
            {formData.team}
          </Text>
        </Stack>
        <Stack direction="row" gap={1} alignItems="baseline">
          <Text element="h5" weight="bold">
            Description:
          </Text>
          <Text color="secondary" variant="h5" weight="regular">
            {formData.description}
          </Text>
        </Stack>

        <Divider />

        <Stack direction="column" gap={1}>
          <Text element="h5" weight="bold">
            Versions Impacted:
          </Text>
          <Box display="flex" direction="column" gap={1}>
            {formData.targets.length > 0 ? (
              formData.targets.map((target, index) => (
                <ExceptionConfirmationVersionRow key={index} target={target} cveID={formData.cve!.value!} />
              ))
            ) : (
              <Text color="secondary" italic>
                No impacted versions
              </Text>
            )}
          </Box>
        </Stack>
      </Stack>

      <Modal.ButtonRow>
        <Button variant="secondary" fill="outline" onClick={onDismiss}>
          Cancel
        </Button>
        <Button
          variant="destructive"
          onClick={onSubmit}
          disabled={!user.isSignedIn || loading}
          tooltip={!user.isSignedIn ? 'Sign in to create an exception' : undefined}
        >
          {loading ? 'Creating...' : 'Create Exception'}
        </Button>
      </Modal.ButtonRow>
    </Modal>
  );
};
