export const typePolicies = {
  BugBountySubmission: {
    keyFields: ['submissionCode'],
  },
  RepositorySourceConfig: {
    fields: {
      scanConfigs: {
        merge(existing = [], incoming: any[]) {
          return [...incoming];
        },
      },
    },
  },
};
