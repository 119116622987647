import { useMutation, useSuspenseQuery } from '@apollo/client';
import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Button, ConfirmModal, Stack, LoadingPlaceholder } from '@grafana/ui';
import { DeleteSourceMutation, GetSourceNameQuery, Role } from '__generated__/graphql';
import { useRole } from 'hooks';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SOURCES_ROUTE } from 'shared/constants/routes/appRoutes';

import { DELETE_SOURCE } from './ManageSourceMutations';
import { GET_SOURCE_NAME } from './ManageSourceQueries';

interface DeleteSourceType {
  sourceID: string;
}

export const DeleteSource = ({ sourceID }: DeleteSourceType) => {
  const { role } = useRole();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { data } = useSuspenseQuery<GetSourceNameQuery>(GET_SOURCE_NAME, {
    variables: {
      id: sourceID,
    },
  });

  const [deleteSource, { loading }] = useMutation<DeleteSourceMutation>(DELETE_SOURCE, {
    ignoreResults: true,
    onCompleted: () => {
      getAppEvents().publish({
        type: AppEvents.alertSuccess.name,
        payload: [`Success: deleted ${data.source.name}`],
      });
      navigate(SOURCES_ROUTE);
    },
    onError: () => {
      getAppEvents().publish({
        type: AppEvents.alertError.name,
        payload: [`Error: failed to delete ${data.source.name}`],
      });
    },
  });

  const handleDeleteSource = () => {
    setIsModalOpen(false);
    deleteSource({
      variables: {
        input: {
          sourceId: sourceID,
        },
      },
    });
  };

  return (
    <>
      <Stack alignItems="baseline">
        <Button
          variant="destructive"
          onClick={() => setIsModalOpen(true)}
          {...(role !== Role.Administrator && {
            disabled: true,
            tooltip: 'Only administrators may perform this action',
          })}
        >
          Delete Source
        </Button>
        {loading && <LoadingPlaceholder text="Deleting..." />}
      </Stack>
      <ConfirmModal
        isOpen={isModalOpen}
        title={`Delete ${data.source.name}?`}
        body={`This will remove all versions, related issues and scans.`}
        confirmText="Delete"
        onConfirm={handleDeleteSource}
        onDismiss={() => setIsModalOpen(false)}
      />
    </>
  );
};
