import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Box, LinkButton, Text, useStyles2 } from '@grafana/ui';
import { CveException, Source, Version } from '__generated__/graphql';
import { formatDate, getSourceImage } from 'components/utils';
import { useNavigate } from 'react-router-dom';
import { GITHUB_PREFIX } from 'shared/constants/routes/externalRoutes';

import { ReasonBox } from './ReasonBox';
import { Status } from './Status';
import { getEntityDetails, getExpirationColor } from './utils';

interface ExploreExceptionsRowType {
  exception: CveException;
  onNavigate: (id: string) => void;
}

export const ExploreExceptionsRow = ({ exception, onNavigate }: ExploreExceptionsRowType) => {
  const styles = useStyles2(getStyles);
  const navigate = useNavigate();

  return (
    <tr key={exception.id}>
      <td>
        <LinkButton className={styles.linkButton} fill="text" onClick={() => onNavigate(exception.id)}>
          {exception.cve.cve}
        </LinkButton>
      </td>
      <td>
        <Text>{exception.cve.title}</Text>
      </td>
      <td>
        <ReasonBox value={exception.reason} />
      </td>
      <td>
        <ul className={styles.list}>
          {exception.entities.map(({ version, source }) => {
            const { name, origin, route } = getEntityDetails(version as Version, source as Source);

            return (
              <li key={`source-${exception.id}-${name}`}>
                <LinkButton className={styles.listItem} fill="text" onClick={() => navigate(route)}>
                  <Box paddingRight={1}>
                    <img src={getSourceImage(origin)} alt="logo" height="18px" width="18px" />
                  </Box>
                  {name.replace(GITHUB_PREFIX, '')}
                  {source && (
                    <Box marginLeft={0.5}>
                      <Text color="error" variant="bodySmall">
                        (All Versions)
                      </Text>
                    </Box>
                  )}
                </LinkButton>
              </li>
            );
          })}
        </ul>
      </td>
      <td className={styles.dateCol}>{formatDate(new Date(exception.createdOn), false)}</td>
      <td className={styles.dateCol}>
        <Text color={getExpirationColor(exception.daysUntilExpiration)}>
          {formatDate(new Date(exception.expiresOn), false)}
        </Text>
      </td>
      <td>
        <Status daysUntilExpiration={exception.daysUntilExpiration} isActive={exception.isActive} />
      </td>
    </tr>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  linkButton: css({
    padding: theme.spacing(0),
  }),
  tags: css({
    display: 'inline-flex',
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    svg: {
      marginRight: theme.spacing(0.5),
    },
  }),
  dateCol: css({
    textAlign: 'center',
  }),
  list: css({
    listStyleType: 'none',
    gap: theme.spacing(1),
  }),
  listItem: css({
    width: 'fit-content',
  }),
});
