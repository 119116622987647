import { Alert, ErrorBoundary, LoadingPlaceholder } from '@grafana/ui';
import { Suspense } from 'react';

import { AddExceptionCommentForm } from './AddExceptionCommentForm';
import { ExceptionCommentList } from './ExceptionCommentList';

interface ExceptionCommentsProps {
  exceptionID: string;
}

export const ExceptionComments = ({ exceptionID }: ExceptionCommentsProps) => {
  return (
    <ErrorBoundary>
      {({ error }) => {
        if (error) {
          return (
            <Alert
              title="Error: failed to load comments"
              onRemove={() => window.location.reload()}
              buttonContent="Reload"
            >
              {error.message}
            </Alert>
          );
        }
        return (
          <Suspense fallback={<LoadingPlaceholder text="Loading comments..." />}>
            <ExceptionCommentList exceptionID={exceptionID} />
            <AddExceptionCommentForm exceptionID={exceptionID} />
          </Suspense>
        );
      }}
    </ErrorBoundary>
  );
};
