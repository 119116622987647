import { useState, useMemo } from 'react';

export enum Direction {
  Asc = 'asc',
  Desc = 'desc',
}

export const useSorting = <T extends Record<string, any>>(
  data: T[],
  defaultKey: keyof T,
  defaultDirection: Direction = Direction.Desc
) => {
  const [sortConfig, setSortConfig] = useState<{ key: keyof T; direction: Direction }>({
    key: defaultKey,
    direction: defaultDirection,
  });

  const sortedData = useMemo(() => {
    if (!data.length) {
      return data;
    }

    const sorted = [...data].sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      const isADate = typeof aValue === 'string' && !isNaN(Date.parse(aValue));
      const isBDate = typeof bValue === 'string' && !isNaN(Date.parse(bValue));

      if (isADate && isBDate) {
        return new Date(aValue).getTime() - new Date(bValue).getTime();
      } else if (typeof aValue === 'number' && typeof bValue === 'number') {
        return aValue - bValue;
      } else if (typeof aValue === 'string' && typeof bValue === 'string') {
        return aValue.localeCompare(bValue);
      }

      return 0;
    });

    return sortConfig.direction === Direction.Asc ? sorted : sorted.reverse();
  }, [data, sortConfig]);

  const handleSort = (key: keyof T) => {
    setSortConfig((current) =>
      current.key === key
        ? { key, direction: current.direction === Direction.Asc ? Direction.Desc : Direction.Asc }
        : { key, direction: Direction.Desc }
    );
  };

  return { sortedData, handleSort, sortConfig };
};
