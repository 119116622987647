import { Field, RadioButtonGroup, Box, Label, Stack, Tooltip, Text, Icon } from '@grafana/ui';
import { Control, Controller, FormState, Path } from 'react-hook-form';

import { UpdateableExceptionFormFields } from '../CreateException';

export enum EXCEPTION_REASONS {
  NotExploitable = 'Not Exploitable',
  FalsePositive = 'False Positive',
  WontFixDeprecated = `Won't Fix (Deprecated)`,
}

interface ReasonFieldType<T extends UpdateableExceptionFormFields> {
  control: Control<T>;
  formState: FormState<T>;
  required?: boolean;
  disabled?: boolean;
}

export const ReasonField = <T extends UpdateableExceptionFormFields>({
  control,
  formState,
  required,
  disabled,
}: ReasonFieldType<T>) => {
  return (
    <Field
      label={
        <Label>
          <Stack alignItems="center">
            <span>Reason{required ? '*' : ''}</span>
            <Tooltip placement="right-end" content={<Text element="p">Why can we ignore this CVE?</Text>}>
              <Icon name="info-circle" size="sm" />
            </Tooltip>
          </Stack>
        </Label>
      }
    >
      <Controller
        name={'reason' as Path<T>}
        rules={{ required }}
        control={control}
        render={({ field }) => (
          <Box width={40}>
            <RadioButtonGroup
              {...field}
              size="md"
              options={Object.values(EXCEPTION_REASONS).map((reason) => ({
                label: reason,
                value: reason,
              }))}
              invalid={!!formState.errors.reason}
              disabled={disabled}
            />
          </Box>
        )}
      />
    </Field>
  );
};
