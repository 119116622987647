import { Stack } from '@grafana/ui';
import { SourceOwnershipControls } from 'components/Ownership';

interface SourceHeaderControlsType {
  sourceID: string;
}

export const SourceHeaderControls = ({ sourceID }: SourceHeaderControlsType) => {
  return (
    <Stack direction="row" gap={2} alignItems="center">
      <SourceOwnershipControls sourceId={sourceID} />
    </Stack>
  );
};
