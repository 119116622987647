import { Box, Icon, Stack, TextLink, Text, Tooltip } from '@grafana/ui';
import { Owner } from '__generated__/graphql';

import { User, ResourceType, UsersIndicator } from './UsersIndicator';

interface OwnerBoxProps {
  owners?: Owner[] | null;
  type: ResourceType;
}
export const OwnerBox = ({ owners, type }: OwnerBoxProps) => {
  const iconOwners: User[] = [];
  owners?.forEach((owner) => {
    iconOwners.push({
      ...(owner.isUser && { avatarUrl: `https://avatars.githubusercontent.com/${owner.githubTeam.replace('@', '')}` }),
      githubName: owner.githubTeam,
      isTeam: !owner.isUser,
    });
  });
  return (
    <Box padding={1} borderColor="weak" borderStyle="solid" display="flex" direction="row" alignItems="center" gap={1}>
      <Stack alignItems="center" direction="row" gap={0.5}>
        <Text variant="h4">Owners</Text>
        <Text color="secondary">
          <Tooltip
            interactive
            placement="bottom-end"
            content={
              <Text>
                Learn more about ownership detection{' '}
                <TextLink
                  href={
                    'https://enghub.grafana-ops.net/docs/default/component/security-vulnerability-obs/technical/owners/owners/'
                  }
                  external
                  inline
                  variant="bodySmall"
                >
                  here
                </TextLink>
              </Text>
            }
          >
            <Icon name="info-circle" size="md" />
          </Tooltip>
        </Text>
      </Stack>
      <UsersIndicator resourceType={type} users={iconOwners} limit={4} />
    </Box>
  );
};
