import { ErrorBoundary, Alert, LoadingPlaceholder } from '@grafana/ui';
import { Suspense } from 'react';

import { CveDescriptionFetcherCve } from './CveDescriptionFetcherCve';
import { CveDescriptionFetcherExceptionId } from './CveDescriptionFetcherExceptionId';

interface CveDescriptionContainerBase {
  cve?: string;
  exceptionID?: string;
}

// Ensure one of cve or exceptionID is provided
type CveDescriptionContainerProps =
  | (Required<Pick<CveDescriptionContainerBase, 'cve'>> & { exceptionID?: string })
  | (Required<Pick<CveDescriptionContainerBase, 'exceptionID'>> & { cve?: string });

export const CveDescriptionContainer = ({ cve, exceptionID }: CveDescriptionContainerProps) => {
  return (
    <ErrorBoundary>
      {({ error }) => {
        if (error) {
          return (
            <Alert
              title="Error: failed to load CVE description"
              onRemove={() => window.location.reload()}
              buttonContent="Reload"
            >
              {error.message}
            </Alert>
          );
        }
        return (
          <Suspense fallback={<LoadingPlaceholder text="Loading..." />}>
            {cve ? (
              <CveDescriptionFetcherCve cve={cve} />
            ) : (
              <CveDescriptionFetcherExceptionId exceptionID={exceptionID!} />
            )}
          </Suspense>
        );
      }}
    </ErrorBoundary>
  );
};
