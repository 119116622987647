import { useSuspenseQuery } from '@apollo/client';
import { Text } from '@grafana/ui';
import { GetVersionIssueCountsQuery } from '__generated__/graphql';

import { GET_VERSION_ISSUE_COUNTS } from './CreateExceptionQueries';

interface ExceptionVersionIssueCountProps {
  cveID: string;
  versionID: string;
}

export const ExceptionVersionIssueCount = ({ cveID, versionID }: ExceptionVersionIssueCountProps) => {
  const { data } = useSuspenseQuery<GetVersionIssueCountsQuery>(GET_VERSION_ISSUE_COUNTS, {
    variables: { id: versionID },
  });

  const issues = data.version?.issues;
  const count = issues.filter((issue) => issue.cveId === cveID && !issue.isDismissed).length;

  return (
    <Text color="secondary" italic>
      (dismisses {count} issue{count === 1 ? '' : 's'})
    </Text>
  );
};
