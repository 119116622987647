import { TypedDocumentNode, gql } from '@apollo/client';
import {
  GetCveReportFromExceptionQuery,
  GetCveReportFromExceptionQueryVariables,
  GetCveReportQuery,
  GetCveReportQueryVariables,
} from '__generated__/graphql';

export const GET_CVE_REPORT: TypedDocumentNode<GetCveReportQuery, GetCveReportQueryVariables> = gql`
  query getCveReport($filters: CveFilters, $first: Int, $after: Int) {
    cves(filters: $filters, first: $first, after: $after) {
      response {
        id
        cve
        cvssScore
        cvssV3
        description
        severity
        publishedDate
        lastModifiedDate
        url
        epssProbability
        scoringAuthority
        issues {
          package
          tool {
            name
          }
        }
      }
    }
  }
`;

export const GET_CVE_REPORT_FROM_EXCEPTION: TypedDocumentNode<
  GetCveReportFromExceptionQuery,
  GetCveReportFromExceptionQueryVariables
> = gql`
  query getCveReportFromException($id: ID!) {
    cveException(id: $id) {
      id
      cve {
        id
        cve
        cvssScore
        cvssV3
        description
        severity
        publishedDate
        lastModifiedDate
        url
        epssProbability
        scoringAuthority
        issues {
          package
          tool {
            name
          }
        }
      }
    }
  }
`;
