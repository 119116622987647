import { useMutation } from '@apollo/client';
import { AppEvents, CurrentUserDTO } from '@grafana/data';
import { config, getAppEvents } from '@grafana/runtime';
import { ButtonVariant, ConfirmModal } from '@grafana/ui';
import { DeleteCveExceptionMutation, UpdateCveExceptionStatusMutation } from '__generated__/graphql';
import { GET_CVE_EXCEPTION_COMMENTS } from 'components/CveExceptions/ExceptionComments/ExceptionCommentsQueries';
import { GET_EXCEPTION } from 'components/CveExceptions/ExceptionDetails/ExceptionDetailsQueries';
import {
  DELETE_CVE_EXCEPTION,
  UPDATE_CVE_EXCEPTION_STATUS,
} from 'components/CveExceptions/ExceptionDetailsHeader/ExceptionDetailsHeaderMutations';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { CVE_EXCEPTIONS_ROUTE } from 'shared/constants/routes/appRoutes';

import { ExceptionUpdate } from './ModifyExceptionControls';

interface ModifyExceptionControlsModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  onSuccess: () => void;
  exceptionID: string;
  updateType: ExceptionUpdate;
}

export const ModifyExceptionControlsModal = ({
  isOpen,
  onDismiss,
  exceptionID,
  updateType,
  onSuccess,
}: ModifyExceptionControlsModalProps) => {
  const navigate = useNavigate();

  const [updateException] = useMutation<UpdateCveExceptionStatusMutation>(UPDATE_CVE_EXCEPTION_STATUS, {
    onCompleted: () => {
      getAppEvents().publish({
        type: AppEvents.alertSuccess.name,
        payload: [`Success: updated exception`],
      });
      onSuccess();
    },
    onError: () => {
      getAppEvents().publish({
        type: AppEvents.alertError.name,
        payload: [`Error: failed to update exception`],
      });
    },
    refetchQueries: [GET_CVE_EXCEPTION_COMMENTS, GET_EXCEPTION],
  });

  const [deleteException] = useMutation<DeleteCveExceptionMutation>(DELETE_CVE_EXCEPTION, {
    onCompleted: () => {
      getAppEvents().publish({
        type: AppEvents.alertSuccess.name,
        payload: [`Success: deleted exception`],
      });
      navigate(CVE_EXCEPTIONS_ROUTE);
    },
    onError: () => {
      getAppEvents().publish({
        type: AppEvents.alertError.name,
        payload: [`Error: failed to delete exception`],
      });
    },
  });

  const getFutureDate = (months: number) => {
    const futureDate = new Date();
    futureDate.setMonth(futureDate.getMonth() + months);
    return futureDate.toISOString();
  };

  const handleUpdateException = useCallback(
    (user: CurrentUserDTO, isActive: boolean, expiresOn?: string) => {
      updateException({
        variables: {
          input: {
            exceptionId: exceptionID,
            isActive,
            ...(expiresOn && { expiresOn }),
            userId: user.id,
            userName: user.name,
            gravatarUrl: user.gravatarUrl,
          },
        },
      });
    },
    [updateException, exceptionID]
  );

  const handleDeleteException = useCallback(() => {
    deleteException({
      variables: { input: { exceptionId: exceptionID } },
    });
  }, [deleteException, exceptionID]);

  const modalContent = useMemo(
    () => ({
      [ExceptionUpdate.RENEW]: {
        title: `Renew Exception?`,
        body: `Renewing an exception reactivates the exception if it's inactive and sets the expiration date to 3 months from the current date.`,
        confirmText: `Renew`,
        confirmVariant: `primary`,
        action: (user: CurrentUserDTO) => handleUpdateException(user, true, getFutureDate(3)),
      },
      [ExceptionUpdate.ACTIVATE]: {
        title: `Activate Exception?`,
        body: `Activating an exception hides related issues across all targeted versions.`,
        confirmText: `Activate`,
        confirmVariant: `primary`,
        action: (user: CurrentUserDTO) => handleUpdateException(user, true),
      },
      [ExceptionUpdate.DEACTIVATE]: {
        title: `Deactivate Exception?`,
        body: `Deactivating an exception reactivates related issues across all targeted versions. This is reversible and does not delete the exception from the database.`,
        confirmText: `Deactivate`,
        confirmVariant: `destructive`,
        action: (user: CurrentUserDTO) => handleUpdateException(user, false),
      },
      [ExceptionUpdate.DELETE]: {
        title: `Delete Exception?`,
        body: `Deleting an exception removes it from the system. This is irreversible.`,
        confirmText: `Delete`,
        confirmVariant: `destructive`,
        action: handleDeleteException,
      },
    }),
    [handleUpdateException, handleDeleteException]
  );

  const { title, body, confirmText, confirmVariant, action } = modalContent[updateType];

  return (
    <ConfirmModal
      isOpen={isOpen}
      title={title}
      body={body}
      confirmText={confirmText}
      confirmVariant={confirmVariant as ButtonVariant}
      onConfirm={() => action(config.bootData.user)}
      onDismiss={onDismiss}
    />
  );
};
