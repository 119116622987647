import { useQuery } from '@apollo/client';
import { Alert } from '@grafana/ui';
import { GetCveExceptionAlertQuery } from '__generated__/graphql';
import { useNavigate } from 'react-router-dom';
import { CVE_EXCEPTIONS_ROUTE } from 'shared/constants/routes/appRoutes';

import { GET_CVE_EXCEPTION_ALERT } from './CveExceptionAlertQueries';

interface CveExceptionAlertType {
  cve: string;
}

export const CveExceptionAlert = ({ cve }: CveExceptionAlertType) => {
  const navigate = useNavigate();
  const { data, loading, error } = useQuery<GetCveExceptionAlertQuery>(GET_CVE_EXCEPTION_ALERT, {
    variables: {
      filters: {
        cve,
      },
    },
  });

  if (loading || error) {
    return null;
  }

  if (!data?.cveExceptions.metadata.totalCount) {
    return null;
  }

  return (
    <Alert
      title={`This CVE has Exceptions!`}
      severity="warning"
      onRemove={() => navigate(`${CVE_EXCEPTIONS_ROUTE}?var-cve=${cve}`)}
      buttonContent="See Details"
    >
      Users have created exceptions for this CVE.
    </Alert>
  );
};
