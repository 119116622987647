import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { GQL_URL } from 'shared/constants/routes/proxiedRoutes';

import { typePolicies } from './typePolicies';

// API Ingester Link
const httpLink = createHttpLink({ uri: GQL_URL });

// Apollo Client Instance
export const apolloClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache({ typePolicies }),
});
