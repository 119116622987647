import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Card, Stack, useStyles2, Text } from '@grafana/ui';

import { UserIcon } from './UserIcon';

export interface User {
  /** GitHub team name or user name*/
  githubName: string;
  /** URL to the user's avatar */
  avatarUrl?: string;
  isTeam: boolean;
}

export enum ResourceType {
  SOURCE = 'Project',
  ISSUE = 'Package',
}

export interface UsersIndicatorProps {
  /** An object that contains the user's details */
  users: User[];
  resourceType: ResourceType;
  /** A limit of how many user icons to show before collapsing them and showing a number of users instead */
  limit?: number;
}
export const UsersIndicator = ({ users, resourceType, limit = 4 }: UsersIndicatorProps) => {
  const styles = useStyles2(getStyles);
  if (!users.length) {
    return (
      <div className={styles.stackContainer}>
        <UserIcon
          user={{ githubName: '', isTeam: false }}
          tooltipContent={<Text color="secondary">No owners detected</Text>}
        />
      </div>
    );
  }
  // Make sure limit is never negative
  limit = limit > 0 ? limit : 4;
  const limitReached = users.length > limit;
  const extraUsers = users.length - limit;
  // Prevent breaking the layout when there's more than 99 users
  const tooManyUsers = extraUsers > 99;

  return (
    <div className={styles.stackContainer}>
      {limitReached && (
        <UserIcon
          user={{ githubName: 'Extra users', avatarUrl: '', isTeam: true }}
          tooltipContent={
            <div className={styles.overflowList}>
              <Stack direction="column" gap={2}>
                {users.reverse().map((user) => {
                  return (
                    <Stack direction="row" alignItems="center" key={user.githubName}>
                      <UserIcon user={user} />
                      {user.githubName}
                    </Stack>
                  );
                })}
              </Stack>
            </div>
          }
        >
          {tooManyUsers ? '...' : `+${extraUsers}`}
        </UserIcon>
      )}
      {users
        .slice(0, limitReached ? limit : limit + 1)
        .reverse()
        .map((user, idx) => {
          if (!user.avatarUrl) {
            user.avatarUrl = `https://avatars.githubusercontent.com/t/${11716000 + idx}`;
            user.isTeam = true;
          } else {
            user.isTeam = false;
          }
          return (
            <UserIcon
              key={user.githubName}
              user={user}
              tooltipContent={
                <Card isCompact className={styles.card}>
                  <Card.Figure>
                    <img src={user.avatarUrl} className={styles.logo} alt={user.githubName} />
                  </Card.Figure>
                  <Card.Heading>
                    <Text variant="h5">{user.githubName}</Text>
                  </Card.Heading>
                  <Card.Meta>{`${resourceType} Owner`}</Card.Meta>
                </Card>
              }
            />
          );
        })}
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    stackContainer: css({
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row-reverse',
      marginLeft: theme.spacing(1.5),
      '& > a': {
        marginLeft: theme.spacing(-1), // Overlay the elements a bit on top of each other
      },
    }),
    card: css({
      paddingBottom: 0,
    }),
    overflowList: css({
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    }),
    logo: css({
      borderRadius: theme.shape.radius.circle,
    }),
  };
};
