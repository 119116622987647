import { Checkbox, Tooltip, Icon } from '@grafana/ui';
import { Control, Controller, Path } from 'react-hook-form';

import { ExceptionFormType } from '../CreateException';

interface AllVersionsFieldProps {
  control: Control<ExceptionFormType>;
  name: Path<ExceptionFormType>;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
}

export const AllVersionsField = ({ control, name, disabled, onChange }: AllVersionsFieldProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Tooltip content="Enabling 'All Versions' includes future versions">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              {...field}
              label="All Versions"
              disabled={disabled}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const checked = e.target.checked;
                field.onChange(checked);

                if (onChange) {
                  onChange(checked);
                }
              }}
            />
            <Icon name="info-circle" size="sm" style={{ marginLeft: '5px' }} />
          </div>
        </Tooltip>
      )}
    />
  );
};
