import {
  EmbeddedScene,
  QueryVariable,
  SceneAppPage,
  SceneControlsSpacer,
  SceneFlexItem,
  SceneFlexLayout,
  SceneVariableSet,
  VariableValueSelectors,
} from '@grafana/scenes';
import { VariableHide } from '@grafana/schema';
import { Icon } from '@grafana/ui';
import { RadioToggle } from 'controls/RadioToggle';
import { SwitchVariable } from 'controls/SwitchVariable';
import { TextInputVariable } from 'controls/TextInputVariable';
import { createInfinityVariable, getInfinityDatasource } from 'datasources/infinity';
import { K8S_NAMESPACE_DETAILS_ROUTE_PATH, K8S_SCANNING_ROUTE } from 'shared/constants/routes/appRoutes';

import { getNamespaceCvesTable } from './panels';
import { getCveCountsPerNamespaceQueryRunner } from './queryRunners';

interface GetNamespacesTabType {
  apiUrl: string;
  clusterID: string;
}

export const getNamespacesTab = ({ apiUrl, clusterID }: GetNamespacesTabType) => {
  const nameVariable = new TextInputVariable({
    name: 'name',
    placeholder: 'Search Namespaces',
    prefix: <Icon name="search" />,
    width: 40,
    hide: VariableHide.hideLabel,
  });

  const tankaTeamVariable = new QueryVariable({
    name: 'tankaTeam',
    label: 'Tanka Team',
    datasource: getInfinityDatasource(),
    placeholder: 'Select Tanka Team',
    includeAll: true,
    defaultToAll: true,
    allValue: 'ALL',
    value: '$__all',
    query: createInfinityVariable({
      refId: 'variable-tanka-team',
      apiUrl,
      queryBody: `
        query getTeams {
          teams {
            __value: tankaTeam
          }
        }
      `,
      overrides: {
        base: {
          root_selector: 'data.teams',
        },
      },
    }),
  });

  const githubTeamVariable = new QueryVariable({
    name: 'githubTeam',
    label: 'GitHub Team',
    datasource: getInfinityDatasource(),
    placeholder: 'Select GitHub Team',
    includeAll: true,
    defaultToAll: true,
    allValue: 'ALL',
    value: '$__all',
    query: createInfinityVariable({
      refId: 'variable-github-team',
      apiUrl,
      queryBody: `
        query getTeams {
          teams {
            __value: githubTeam
          }
        }
      `,
      overrides: {
        base: {
          root_selector: 'data.teams',
        },
      },
    }),
  });

  const namespaceTableFlexItem = (hideEmptyRows: boolean) =>
    new SceneFlexItem({
      $data: getCveCountsPerNamespaceQueryRunner(apiUrl, clusterID),
      body: getNamespaceCvesTable(clusterID, hideEmptyRows),
    });

  const layout = new SceneFlexLayout({
    direction: 'column',
    width: '100%',
    height: '100%',
    children: [namespaceTableFlexItem(false)],
  });
  const hideEmptyToggle = new RadioToggle({
    options: [
      { value: 'hideEmpty', label: 'Hide Empty Rows' },
      { value: 'showAll', label: 'Show All' },
    ],
    value: 'showAll',
    onChange: (value) => {
      layout.setState({
        children: [namespaceTableFlexItem(value === 'hideEmpty')],
      });
    },
  });
  const outOfSloVariable = new SwitchVariable({
    name: 'outOfSlo',
    label: 'Out of SLO',
    description: 'Only show CVEs that are out of SLO. We only track CVEs for production clusters.',
  });

  return new SceneAppPage({
    title: 'Namespaces',
    key: 'k8sNamespaces',
    url: `${K8S_SCANNING_ROUTE}/${clusterID}/namespaces`,
    routePath: K8S_NAMESPACE_DETAILS_ROUTE_PATH,
    getScene: () => {
      return new EmbeddedScene({
        key: 'k8sNamespaceTab',
        $variables: new SceneVariableSet({
          variables: [nameVariable, tankaTeamVariable, githubTeamVariable, outOfSloVariable],
        }),
        body: new SceneFlexLayout({
          direction: 'column',
          children: [layout],
        }),
        controls: [new VariableValueSelectors({}), new SceneControlsSpacer(), hideEmptyToggle],
      });
    },
  });
};
