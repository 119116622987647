import { useSuspenseQuery } from '@apollo/client';
import { Alert, ErrorBoundary } from '@grafana/ui';
import { GetIssueOwnersQuery } from '__generated__/graphql';

import { OwnerBox } from './OwnerBox';
import { GET_ISSUE_OWNERS } from './OwnershipControlQueries';
import { ResourceType } from './UsersIndicator';

interface OwnershipHeaderType {
  issueId: string;
}

const Controls = ({ issueId }: OwnershipHeaderType) => {
  const {
    data: { issue },
  } = useSuspenseQuery<GetIssueOwnersQuery>(GET_ISSUE_OWNERS, {
    variables: {
      id: issueId,
    },
  });
  return <OwnerBox owners={issue.owners} type={ResourceType.ISSUE} />;
};

export const IssueOwnershipControls = ({ issueId }: OwnershipHeaderType) => (
  <ErrorBoundary>
    {({ error }) => {
      if (error) {
        return (
          <Alert title="Error: failed to load owners" onRemove={() => window.location.reload()} buttonContent="Reload">
            {error.message}
          </Alert>
        );
      }
      return <Controls issueId={issueId} />;
    }}
  </ErrorBoundary>
);
