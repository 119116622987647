import { ErrorBoundary, Alert, LoadingPlaceholder } from '@grafana/ui';
import { Suspense } from 'react';

import { CveSummaryTableFetcherCve } from './CveSummaryTableFetcherCve';
import { CveSummaryTableFetcherExceptionId } from './CveSummaryTableFetcherExceptionId';

interface CveSummaryTableBase {
  cve?: string;
  exceptionID?: string;
}

// Ensure one of cve or exceptionID is provided
type CveSummaryTableProps =
  | (Required<Pick<CveSummaryTableBase, 'cve'>> & { exceptionID?: string })
  | (Required<Pick<CveSummaryTableBase, 'exceptionID'>> & { cve?: string });

export const CveSummaryTableContainer = ({ cve, exceptionID }: CveSummaryTableProps) => {
  return (
    <ErrorBoundary>
      {({ error }) => {
        if (error) {
          return (
            <Alert
              title="Error: failed to load CVE summary table"
              onRemove={() => window.location.reload()}
              buttonContent="Reload"
            >
              {error.message}
            </Alert>
          );
        }
        return (
          <Suspense fallback={<LoadingPlaceholder text="Loading..." />}>
            {cve ? (
              <CveSummaryTableFetcherCve cve={cve} />
            ) : (
              <CveSummaryTableFetcherExceptionId exceptionID={exceptionID!} />
            )}
          </Suspense>
        );
      }}
    </ErrorBoundary>
  );
};
