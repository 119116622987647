import { css } from '@emotion/css';
import { GrafanaTheme2, renderMarkdown } from '@grafana/data';
import { useStyles2, Card, IconButton, Text, Stack, Divider, Box, LinkButton } from '@grafana/ui';
import { CveException, Source, Version } from '__generated__/graphql';
import { EXCEPTION_REASONS } from 'components/CveExceptions/CreateException/Fields/ReasonField';
import { Status } from 'components/CveExceptions/ExploreExceptions/Status';
import { getEntityDetails } from 'components/CveExceptions/ExploreExceptions/utils';
import { formatDate, getSourceImage } from 'components/utils';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GITHUB_PREFIX } from 'shared/constants/routes/externalRoutes';

import { EditExceptionModal } from './EditExceptionModal';

const titleEmojiMap = {
  [EXCEPTION_REASONS.FalsePositive]: '🎭',
  [EXCEPTION_REASONS.NotExploitable]: '🛡️',
  [EXCEPTION_REASONS.WontFixDeprecated]: '⏳',
};

interface ExceptionDescriptionCardProps {
  exception: CveException;
}

export const ExceptionDescriptionCard = ({ exception }: ExceptionDescriptionCardProps) => {
  const styles = useStyles2(getStyles);
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const onSuccess = () => {
    setIsModalOpen(false);
  };

  const { createdOn, lastModifiedOn, wasModified, expiresOn } = useMemo(
    () => formatDates(exception.createdOn, exception.lastModifiedOn, exception.expiresOn),
    [exception.createdOn, exception.lastModifiedOn, exception.expiresOn]
  );

  const expirationColor = useMemo(
    () => getExpirationColor(exception.daysUntilExpiration),
    [exception.daysUntilExpiration]
  );

  const { isMarkdownHeading, renderedMarkdown } = useMemo(
    () => ({
      isMarkdownHeading: exception.description.trim().startsWith('#'),
      renderedMarkdown: renderMarkdown(exception.description),
    }),
    [exception.description]
  );

  return (
    <>
      <Card>
        <Card.Heading>
          <Text variant="h3">{`${titleEmojiMap[exception.reason as EXCEPTION_REASONS]} ${exception.reason}`}</Text>
          <IconButton name="edit" tooltip="Edit Exception" onClick={() => setIsModalOpen(true)} />
        </Card.Heading>
        <div className={styles.description}>
          <Stack direction="column">
            <Stack direction="row" gap={1} alignItems="baseline">
              <Text element="h5" weight="bold">
                Status:
              </Text>
              <Status daysUntilExpiration={exception.daysUntilExpiration} isActive={exception.isActive} />
            </Stack>
            <Stack direction="row" gap={1} alignItems="baseline">
              <Text element="h5" weight="bold">
                Created By:
              </Text>
              <Text variant="h5" color="secondary" weight="regular">
                {exception.team}
              </Text>
            </Stack>
            <Stack direction="row" gap={1} alignItems="baseline">
              <Text element="h5" weight="bold">
                Created:
              </Text>
              {wasModified ? (
                <Text color="secondary" variant="h5" weight="regular">
                  <del>{createdOn}</del>
                </Text>
              ) : (
                <Text variant="h5" color="secondary" weight="regular">
                  {createdOn}
                </Text>
              )}
            </Stack>
            {wasModified && (
              <Stack direction="row" gap={1} alignItems="baseline">
                <Text element="h5" weight="bold">
                  Modified:
                </Text>
                <Text variant="h5" color="secondary" weight="regular">
                  {lastModifiedOn}
                </Text>
              </Stack>
            )}
            <Stack direction="row" gap={1} alignItems="baseline">
              <Text element="h5" weight="bold">
                Expires On:
              </Text>
              <Text color={expirationColor} variant="h5" weight="regular">
                {expiresOn}
              </Text>
            </Stack>
            <Stack direction={isMarkdownHeading ? 'column' : 'row'} gap={1} alignItems="baseline">
              <Text element="h5" weight="bold">
                Description:
              </Text>
              <span
                className="markdown-html"
                // nosemgrep: typescript.react.security.audit.react-dangerouslysetinnerhtml.react-dangerouslysetinnerhtml
                dangerouslySetInnerHTML={{ __html: renderedMarkdown }}
              />
            </Stack>
            <Divider />
            <Stack direction="column" gap={0}>
              <Box marginBottom={1}>
                <Text element="h5" weight="bold">
                  Impacted Versions:
                </Text>
              </Box>
              {exception.entities.length > 0 ? (
                exception.entities.map(({ version, source }, index) => {
                  const { name, origin, route } = getEntityDetails(version as Version, source as Source);

                  return (
                    <Box key={index}>
                      <LinkButton className={styles.entity} fill="text" onClick={() => navigate(route)} fullWidth>
                        <Box paddingRight={1}>
                          <img src={getSourceImage(origin)} alt="logo" height="18px" width="18px" />
                        </Box>
                        {name.replace(GITHUB_PREFIX, '')}
                        {source && (
                          <Box marginLeft={0.5}>
                            <Text color="error" variant="bodySmall">
                              (All Versions)
                            </Text>
                          </Box>
                        )}
                      </LinkButton>
                    </Box>
                  );
                })
              ) : (
                <Text color="secondary" italic>
                  This exception does not impact any versions. This likely means that the impacted versions were deleted
                </Text>
              )}
            </Stack>
          </Stack>
        </div>
      </Card>
      <EditExceptionModal
        isOpen={isModalOpen}
        exception={exception}
        onDismiss={() => setIsModalOpen(false)}
        onSuccess={onSuccess}
      />
    </>
  );
};

const formatDates = (createdOn: string, lastModifiedOn: string, expiresOn: string) => {
  const createdDate = formatDate(new Date(createdOn));
  const modifiedDate = formatDate(new Date(lastModifiedOn));
  const expiresDate = formatDate(new Date(expiresOn));
  const wasModified = modifiedDate !== 'January 1, 1970 at 12:00 AM UTC' && modifiedDate !== createdDate;
  return { createdOn: createdDate, lastModifiedOn: modifiedDate, wasModified, expiresOn: expiresDate };
};

const getExpirationColor = (daysUntilExpiration: number) => {
  if (daysUntilExpiration > 30) {
    return 'secondary';
  }
  if (daysUntilExpiration > 14) {
    return 'warning';
  }

  return 'error';
};

const getStyles = (theme: GrafanaTheme2) => ({
  description: css({
    gridArea: 'Description',
    paddingTop: theme.spacing(2),
  }),
  entity: css({
    paddingLeft: theme.spacing(0),
  }),
});
